import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginInformations } from 'src/model/LoginInformations';
import { ChangePasswordRequest } from '../../../model/service/ChangePasswordRequest';
import { SaveAccountRequest } from '../../../model/service/SaveAccountRequest';
import { User } from 'src/model/users/User';
import { Service } from './service';
import { UserPlanID } from 'src/model/users/UserPlanId';
import { Observable } from 'rxjs';
import { UpdateCGUCGValidation } from 'src/model/users/TermsAndConditions';

@Injectable({
  providedIn: 'root'
})

export class UserService extends Service {
  constructor(public dialog: MatDialog, public override http: HttpClient) {
    super(http);
  }

  override controller = 'User';
  getAccount: string = 'GetAccount';
  saveAccount: string = 'SaveAccount';
  getIdProfil: string = 'GetIdProfil';
  signUpMethod: string = 'SignUp';
  resetPasswordMethod: string = 'ResetPassword';
  changePassword: string = 'ChangePassword';
  updatePolicies: string = 'UpdateCGUCGVValidation';

  GetAccount(): any {
    let data: any = this.HttpPost(this.controller, this.getAccount, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  SaveAccount(saveAccountRequest: SaveAccountRequest): any {
    let data: any = this.HttpPost(this.controller, this.saveAccount, saveAccountRequest);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetIdProfil(): Observable<UserPlanID> {
    let data: any = this.HttpPostGeneric(this.controller, this.getIdProfil, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  SignUp(request: User): any {
    let data: any = this.HttpPost(this.controller, this.signUpMethod, request, true);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  ResetPassword(request: LoginInformations): any {
    let data: any = this.HttpPost(this.controller, this.resetPasswordMethod, request, true);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  ChangePassword(changePasswordRequest: ChangePasswordRequest): any {
    let data: any = this.HttpPost(this.controller, this.changePassword, changePasswordRequest);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  UpdatePolices(termsAndConditions: UpdateCGUCGValidation): any {
    let data = this.HttpPostGeneric(this.controller, this.updatePolicies, termsAndConditions);
    JSON.parse(JSON.stringify(data));
    return data;
  }

}




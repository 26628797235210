<div class="interval-container">
  <div class="divIntervals">
    <label [ngClass]="cssClass1w" class="label-interval" interval="1w" (click)="RefreshIntervalStyle('1w')" title="1 SEMAINE">1 SEMAINE</label>
    <label [ngClass]="cssClass1m"  class="label-interval"  interval="1m" (click)="RefreshIntervalStyle('1m')" title="1 MOIS">1 MOIS</label>
    <label [ngClass]="cssClass3m"  class="label-interval"  interval="3m" (click)="RefreshIntervalStyle('3m')" title="3 MOIS">3 MOIS</label>
    <label [ngClass]="cssClass6m"  class="label-interval"  interval="6m" (click)="RefreshIntervalStyle('6m')" title="6 MOIS">6 MOIS</label>
    <label [ngClass]="cssClassMax" class="label-interval" interval="max" (click)="RefreshIntervalStyle('max')" title="MAX">MAX</label>
  </div>
  <div class="divButtons">
    @if (pricesType=='Futures') {
      <div class="divToggleGraph">
        <mat-icon id="imgLinear" class="{{cssClassImgLinear}}" title="Ligne" aria-hidden="false" (click)="ToggleGraphType(false)" aria-label="show chart" fontIcon="show_chart"></mat-icon>
        <mat-icon id="imgCandlestick" class="{{cssClassImgCandlestick}}" title="Chandeliers" aria-hidden="false" (click)="ToggleGraphType(true)" aria-label="candlestick chart" fontIcon="candlestick_chart"></mat-icon>
      </div>
    }
    <mat-icon aria-hidden="false" aria-label="download_for_offline" (click)="ExportGraph()" title="Exporter" fontIcon="download_for_offline"></mat-icon>
  </div>
  
</div>

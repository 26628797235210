import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalEnums } from '../../../global';
import { SaveAccountRequest } from '../../../model/service/SaveAccountRequest';
import { UI } from '../../../tools/UI';
import { User } from '../../../tools/User';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { UserService } from '../../services/http/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserInformation } from 'src/model/users/UserInformation';




@Component({
  selector: 'app-informations',
  templateUrl: './my_informations.component.html',
  styleUrls: ['./my_informations.component.scss']
})

@Injectable()
export class MyInformationsComponent implements OnInit {

  informationForm = new FormGroup({
    firstname: new FormControl(null, Validators.required,),
    lastname: new FormControl(null, Validators.required),
    phoneNumber: new FormControl(null, Validators.required)
  })
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  informationValue: UserInformation;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private notifyService: NotificationService, private userService: UserService) { }

  ngOnInit(): void {
    this.LoadData();
  }

  ngAfterViewInit() {
  }

  LoadData() {
    this.userService.GetAccount().subscribe({
      next: (value: UserInformation) => {
        this.informationForm.setValue({
          firstname: value.firstName,
          lastname: value.lastName,
          phoneNumber: value.phoneNumber
        })
        this.informationValue = value;
      },
      error: (err: { status: number; }) => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      },
      complete: () => {
        console.log("data loaded")
      }
    })
  }

  SaveData() {
    let saveAccountRequest: SaveAccountRequest = new SaveAccountRequest();
    saveAccountRequest.LastName = this.informationForm.value.lastname;
    saveAccountRequest.FirstName = this.informationForm.value.firstname;
    saveAccountRequest.PhoneNumber = this.informationForm.value.phoneNumber;




    // if (saveAccountRequest.LastName.trim().length == 0) {
    //   this.notifyService.ShowError("Le nom ne doit pas être vide", "")
    // }
    // else if (saveAccountRequest.FirstName.trim().length == 0) {
    //   this.notifyService.ShowError("Le prénom ne doit pas être vide", "")
    // }
    // else if (saveAccountRequest.PhoneNumber.trim().length == 0) {
    //   this.notifyService.ShowError("Le numéro de téléphone ne doit pas être vide", "")
    // }
    // else {
    this.userService.SaveAccount(saveAccountRequest).subscribe({
      next: () => {
        if (saveAccountRequest.FirstName.trim().length > 0)
          User.GlobalFunctionsUser.SetFirstName(saveAccountRequest.FirstName);

        if (saveAccountRequest.LastName.trim().length > 0)
          User.GlobalFunctionsUser.SetLastName(saveAccountRequest.LastName);

        if (saveAccountRequest.PhoneNumber.trim().length > 0)
          User.GlobalFunctionsUser.SetPhoneNumber(saveAccountRequest.PhoneNumber);

        UI.GlobalFunctionsUI.RefreshHeaderName();

        this.notifyService.ShowSuccess("Sauvegarde effectuée", "");
      },
      err: (err) => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      },
      complete: () => {

      }
    }


    );
  }
  // }

}






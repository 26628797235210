import { Component, Injectable, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/services/header/header.component.service';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { GetContributionStrategyRequest } from 'src/model/contribution/GetContributionStrategyRequest';
import { Strategy } from 'src/model/Strategy';
import { ContributionService } from 'src/app/services/http/contribution.service';

@Component({
  selector: 'analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})

@Injectable()
export class AnalysisComponent implements OnInit {

  productId: number;
  lastDataAnalysis: string = "";
  lastDate: string = "";

  constructor(private notifyService: NotificationService, private headerService: HeaderService, private contributionService: ContributionService) {

    this.getDate();

  }

  ngOnInit(): void { }

  setHeaderTitle() {
    this.headerService.setTitle("Analyse du" + " " + this.lastDate);

  }

  getDate() {
    let request: GetContributionStrategyRequest = new GetContributionStrategyRequest();
    // Product ID refers to common wheat (Blé tendre)
    request.ProductId = 1;
    request.StrategyId = 1;

    this.contributionService.GetContributionStrategy(request).subscribe({
      next: (data: Strategy) => {
        this.lastDate = data.date
      },
      error: (err: any) => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur lors du chargement des données", "")
        }
      },
      complete: () => {
        this.setHeaderTitle();

      }
    })

  }
}

<div class="component-container">

  <div class="scrollY {{classBlurred}}">
    <!-- Header strategy -->
    <app-strategy-header [strategyHeaderProductId]="sentProductId"></app-strategy-header>
    <div class="strategy-container">
      <app-strategy [receivedProductId]="sentProductId" [strategyId]="1"></app-strategy>
      <app-strategy [receivedProductId]="sentProductId" [strategyId]="2"></app-strategy>
    </div>

    <!-- Header Opinion  -->
    <app-expert-opinion-header [expertOpinionHeaderProductId]="sentProductId"></app-expert-opinion-header>
    <app-expert-opinion [productId]="sentProductId"></app-expert-opinion>

    <app-decrypt [productId]="sentProductId"></app-decrypt>
    <app-focus [productId]="sentProductId"></app-focus>
    <!-- <app-chart [productId]="3"></app-chart> -->

  </div>

</div>
import { Component, Injectable, Input, OnInit } from '@angular/core';
import { ChangePasswordRequest } from '../../../model/service/ChangePasswordRequest';
import { User } from '../../../tools/User';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { UserService } from '../../services/http/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change_password.component.html',
  styleUrls: ['./change_password.component.scss']
})

@Injectable()
export class ChangePasswordComponent implements OnInit {
  @Input() CurrentPassword: string = '';
  @Input() NewPassword: string = '';
  @Input() NewPasswordConfirm: string = '';
  hidePassword: boolean = true;
  hideNewpassword: boolean = true;
  hideNewPasswordConfirm: boolean = true;

  constructor(private notifyService: NotificationService, private userService: UserService) {

  }

  ngOnInit(): void {
  }




  ChangePassword() {
    const currentPassword = this.CurrentPassword.trim();
    const newPassword = this.NewPassword.trim();
    const newPasswordConfirm = this.NewPasswordConfirm.trim();

    if (currentPassword.length < 6) {
      this.notifyService.ShowError("Le mot de passe actuel doit avoir une longueur minimale de 6 caractères", "")
    }
    else if (newPassword.length < 6) {
      this.notifyService.ShowError("Le nouveau mot de passe doit avoir une longueur minimale de 6 caractères", "")
    }
    else if (newPasswordConfirm.length < 6) {
      this.notifyService.ShowError("La confirmation du nouveau mot de passe doit avoir une longueur minimale de 6 caractères", "")
    }
    else if (newPassword != newPasswordConfirm) {
      this.notifyService.ShowError("La confirmation du nouveau mot de passe doit être identique au nouveau mot de passe", "")
    }
    else if (newPassword == currentPassword) {
      this.notifyService.ShowError("Le nouveau nouveau mot de passe doit être différent du mot de passe actuel", "")
    }
    else {
      let changePasswordRequest: ChangePasswordRequest = new ChangePasswordRequest();

      changePasswordRequest.Email = User.GlobalFunctionsUser.GetEmail();
      changePasswordRequest.CurrentPassword = currentPassword;
      changePasswordRequest.NewPassword = newPassword;

      this.userService.ChangePassword(changePasswordRequest).subscribe(data => {
        this.notifyService.ShowSuccess("Le changement de mot de passe a été enregistré", "");
      },
        err => {
          if (err.status !== 401) {
            if (err.error.detail == "BadPassword") {
              this.notifyService.ShowError("Mot de passe incorrect", "");
            }
            else {
              this.notifyService.ShowError("Une erreur s'est produite lors du changement de mot de passe", "");
            }
          }
        });
    }
  }

}

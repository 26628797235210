import { Component, Injectable, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-strategy-header',
  templateUrl: './strategy_header.component.html',
  styleUrls: ['./strategy_header.component.scss']
})

@Injectable()
export class StrategyHeaderComponent implements OnInit {

  urlImage: string = '';
  strategyDate: string = '';
  @Input() strategyHeaderProductId: number;

  constructor() { }

  ngOnInit(): void {
    this.urlImage = 'assets/images/Background_' + this.strategyHeaderProductId + '.png';
  }
}

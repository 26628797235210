import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrl: './modal-image.component.scss'
})
export class ModalImageComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {image: string}) { }
}

import { Injectable } from '@angular/core';
import { LoginInformations } from '../../../model/LoginInformations';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})

export class LoginService extends Service {
  override controller = 'Connection';
  loginMethod: string = 'Login';
  loginUser(request : LoginInformations){

    let data =  this.HttpPostGeneric(this.controller, this.loginMethod, request, true);

    JSON.parse(JSON.stringify(data)); 
    
    return data; 

  }
}




import { Component, Injectable, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery/dist/jquery.min.js';
import { HeaderService } from 'src/app/services/header/header.component.service';
import { Company } from '../../../model/Company';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';

@Component({
  selector: 'tabMyFarm',
  templateUrl: './tab_my_farm.component.html',
  styleUrls: ['./tab_my_farm.component.css']
})

@Injectable()
export class TabMyFarmComponent implements OnInit {
  form: FormGroup | undefined;

  errorMessage = '';
  company: Company;

  CompanyId: number;
  TotalLandArea: number;
  RiskProfile: number;
  ZipCode: string;

  selectedRisk: string;

  riskProfiles = [
    { value: '1', text: 'Equilibré' },
    { value: '2', text: 'Sécurisé' }
  ];

  constructor(private notifyService: NotificationService, private companyService: CompanyService, private activatedRoute : ActivatedRoute , private headerService : HeaderService) {
  }

  ngOnInit(): void {
    //this.LoadData();
    this.setHeaderTitle();
  }

  ChangeRisk(val) {
    this.RiskProfile = +val;
  }

  // AddBorders(id) {
  //   var element = document.getElementById(id);
  //   element.classList.add("mat-form-field-focus");

  //   $(".cdk-overlay-pane").css("position", "relative");
  //   $(".cdk-overlay-pane").css("left", "419px !important");
  //   $(".cdk-overlay-pane").css("transform", "translateX(-5px) translateY(28px)");
  // }

  // RemoveBorders(id) {
  //   var element = document.getElementById(id);
  //   element.classList.remove("mat-form-field-focus");
  // }

  public LoadData() {
    this.companyService.GetCompany().subscribe(data => {
      $("#TotalLandArea").val(data.surface);
      this.RiskProfile = (data.isModeSecure) ? 2 : 1;
      $("#ZipCode").val(data.zipCode);

      this.selectedRisk = this.RiskProfile.toString();
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }

  ngAfterViewInit() {
  }

  Save(): void {
    this.UpdateData();
  }

  UpdateData() {
    let company: Company = new Company();
    company.surface = $("#TotalLandArea").val();
    company.zipCode = $("#ZipCode").val();
    company.isModeSecure = (this.RiskProfile == 2);

    if (this.ValidateData(company)) {
      this.companyService.SaveCompany(company).subscribe(data => {
        this.notifyService.ShowSuccess("Sauvegarde effectuée", "");
      },
        err => {
          if (err.status !== 401) {
            this.notifyService.ShowError("Erreur", "");
          }
        });
    }
  }

  ValidateData(company: Company): boolean {
    let returnValue: boolean = false;

    if (isNaN(company.surface)) {
      this.notifyService.ShowError("Erreur : la surface doit être numérique.", "");
    }
    else if (company.surface.toString().trim() == "") {
      this.notifyService.ShowError("Erreur : la surface est obligatoire.", "");
    }
    else if (company.surface > 9999999) {
      this.notifyService.ShowError("Erreur : la surface doit être inférieur ou égale à 9999999.", "");
    }
    else if (company.zipCode.toString().trim() == "") {
      this.notifyService.ShowError("Erreur : le code postal est obligatoire.", "");
    }
    else if (company.zipCode.toString().trim().length > 5) {
      this.notifyService.ShowError("Erreur : le code postal doit faire au maximum 5 caractères.", "");
    }
    else {
      returnValue = true;
    }

    return returnValue;
  }

  setHeaderTitle() {
    const headerTitleFromRoute: string = this.activatedRoute.snapshot.data['headerTitle']; 
    this.headerService.setTitle(headerTitleFromRoute); 
  }


}

<div class="container-login">
  <form [formGroup]="loginForm" (ngSubmit)="login()" class="form-logo">
    <div class="form-and-logo">
      <div class="container-logo">
        <img src="../../../assets/images/logo/agritel-farmer/agritelFullColoredLogoWithArgus.png" width="265"
          alt="Agritel Farmer" class="logo">
      </div>
      <div class="login-infos">
        <div>       
          <mat-form-field class="width_100">
            <mat-label>Email</mat-label>
            <input matInput #input maxlength="100" placeholder="Email" required="required" id="Email"
              formControlName="email" autocomplete="email">
          </mat-form-field>
        </div>
        <mat-form-field class="width_100">
          <mat-label>Mot de passe</mat-label>
          <input matInput #input [type]="hide ? 'password' : 'text'" maxlength="50" placeholder="Mot de passe"
            id="PasswordLogin" formControlName="password" autocomplete="current-password">
          <button mat-icon-button matSuffix class="buttonTogglePassword" (click)="hide = !hide"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div>
          <a routerLink="/mot-de-passe">Mot de passe oublié ?</a>
        </div>
        <div class="form-actions">
          @if (!isSuccessful) {
          <div class="alert alert-danger text-center">Email ou mot de passe incorrect</div>
          }
          @if (isLoginServiceError) {
          <div class="alert alert-danger text-center">Erreur de connexion</div>
          }
          <button type="submit" id="btnConnexion" class="buttonFullTextBoldUppercase upper width_100">Connexion</button>
          <div class="create-account">
            <a href="https://alabordage.captainfarmer.com/demande-dinformation-agritel-farmer" target="_blank" class="buttonOutlinedTextBold width_100">Pas encore de compte ?</a>
          </div>
        </div>
      </div>
    </div>


    <div id="legalMentions" class="copyright">
      <legal_mentions></legal_mentions>
    </div>
  </form>
</div>
<app-account-nav></app-account-nav>
<div class="report">
  <div class="report-container">
    <div class="report-file">
      <div class="report-header-container">
        <h4>Mes rapports</h4>
      </div>
      <div class="reports-content-container">
        @for (item of reportsHistoryArray; track item) {
        <div>
          <div class="reports-text-container">
            <div>
              <h5> <img src='{{ "../../../assets/images/svg/" + item.imgName }}' alt="logo ble" width="32">
                {{item.reportTypeName}}
                - N ° {{item.number}}</h5>
            </div>
            <div class="action-reports">
              <!-- <a (click)="downloadReports(item.fileUrl, item.fileName)" download target="_blank"
                    title="Télécharger"><mat-icon aria-hidden="false" aria-label="downloadhome icon" fontIcon="download"
                  color="white"> </mat-icon>
                </a> -->
              <a href="{{item.fileUrl}}" target="_blank" title="Prévisualiser"><mat-icon aria-hidden="false"
                  aria-label="preview home icon" fontIcon="preview" color="white"> </mat-icon> </a>
            </div>
          </div>
          <p>Du {{item.date | date :'dd/MM/yyyy'}} </p>
          <hr>
        </div>
        }
      </div>
    </div>
    <div>
      <app-report-notification></app-report-notification>
    </div>
</div>

<div class="container-password-forgotten">
  <div class="logo-container">
    <img src="../../../assets/images/logo/agritel-farmer/agritelFullColoredLogoWithArgus.png" alt="Agritel Farmer" width="265" class="logo">
  </div>
  <div class="form-group">
    <form [formGroup]="passwordReset" (ngSubmit)="ResetPassword()">
      <mat-form-field id="mat-form-field-Email" class="mat-form-field-large width_100">
        <mat-label>Email</mat-label>
        <input matInput id="Email" name="Email" formControlName="email" required="required" placeholder="Email"
          type="text">
      </mat-form-field>
      <div class="form-actions">
        <button type="submit" class="buttonFullTextBoldUppercase width_100 btn btn-block upper">Ré-initialiser mon mot
          de passe</button>
        <button routerLink="/connexion" class="buttonOutlined width_100 btn btn-block orangetextbutton">Retour</button>
      </div>
    </form>
  </div>

  <div id="legalMentions" class="copyright show">
    <legal_mentions></legal_mentions>
  </div>
</div>
<div class="legal">
  <p> 2018 - {{GetCurrentYear()}} © <a href="http://www.agritel.com" target="_blank">Agritel</a>, par
    Argus Media France - Tous droits réservés.</p>
  <p>
    Immatriculé sous le n° 13000284 auprès de l'Orias, www.orias.fr, CIF membre de la CNCIF -
    <a href="https://www.farmer.agritel.com/mentions-legales/" target="_blank"> Mentions légales </a>
  </p>
  @if (showInfoFooter()) {
  <p>
    <a href="https://files.captainfarmer.com/captainfarmer/Terms_And_Conditions/CGV_Agritel_Farmer.pdf" target="_blank">
      CGV
    </a>
  </p>
  }
  <p> Une question ? Un problème ? Contactez nous au 07 66 39 29 79 ou par mail via help&#64;farmer.agritel.com</p>


</div>
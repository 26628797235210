import { Component } from '@angular/core';

@Component({
  selector: 'app-minute-chrono',
  templateUrl: './minute-chrono.component.html',
  styleUrls: ['./minute-chrono.component.scss']
})
export class MinuteChronoComponent {

}

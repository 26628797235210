import { Platform } from "src/model/Platform";

export namespace OS {
  export class GlobalFunctionsOS {
    public static MobileOrDesktop(): number {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

      if (regex.test(navigator.userAgent)) {
        return 1; 
      }
      else {
        return 2; 
      }
    }

  }
}

<div class="">
  <div class="datepicker-container">
    <div class="error-container">
      <div *ngIf="dateSelectedEdito.invalid && (dateSelectedEdito.dirty || dateSelectedEdito.touched)">
        <div *ngIf="dateSelectedEdito.controls['date'].hasError('matDatepickerMin')">
          <p class="error-message">Merci de choisir une date plus récente.</p>
        </div>
        <div *ngIf="dateSelectedEdito.controls['date'].hasError('matDatepickerFilter')">
          <p class="error-message">La quotidienne n'est pas disponible les week-ends.</p>
        </div>
        <div *ngIf="dateSelectedEdito.controls['date'].hasError('matDatepickerMax')">
          <p class="error-message">Merci de choisir une date inferieur à la date actuelle.</p>
        </div>
      </div>
    </div>
    <div class="form-container">
      <div>
        <form [formGroup]="dateSelectedEdito" (ngSubmit)="onSubmit()" class="form-datepicker">
          <mat-form-field>
            <mat-label>Choissez une date</mat-label>
            <input matInput [matDatepicker]="datePicker" [matDatepickerFilter]="filterWeekendOnCalendar"
              formControlName="date" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker [touchUi]="isMobile" disabled="false">
            </mat-datepicker>
          </mat-form-field>
          <button type="submit" [disabled]="!dateSelectedEdito.valid" class="buttonOutlinedTextBoldSmall">Valider</button>
        </form>
      </div>
    </div>
  </div>

  <div class="edito-content-container">
    <div class="edito">
      @for (item of edito; track $index) {
      <div class="content">
        <div class="edito-market-title">
          @switch (item.currentImg) {
          @case ("europ") {
          <div class="eu-market">
            <h3 class="title"> Marché européen</h3>
            <p class="title">Mis à jour le {{item.dateMAJ | date}}</p>
          </div>
          } @case ("marketus") {
          <div class="us-market">
            <h3 class="title"> Marché américain</h3>
            <p class="title">Mis à jour le {{item.dateMAJ | date}}</p>
          </div>
          }
          @default {
          <p>Marché</p>
          }
          }

        </div>
        <div class="edito-header">
          @if (item.title != "") {
          {{item.title}}
          }

        </div>
        <hr>
        <div class="edito-content">
          <p [innerHTML]="item.partOne | safeHtml"></p>

          @if (item.isVisiblePartTwo === true) {
          <p [innerHTML]="item.partTwo | safeHtml"></p>

          }
        </div>
      </div>

      }
    </div>

  </div>
</div>
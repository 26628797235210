import { Component, Injectable, Input, OnInit } from '@angular/core';
import { GetContributionStrategyRequest } from '../../../model/contribution/GetContributionStrategyRequest';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { ContributionService } from '../../services/http/contribution.service';
import { Strategy } from 'src/model/Strategy';

@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.scss']
})

@Injectable()
export class StrategyComponent implements OnInit {

  cssClass: string;
  @Input() receivedProductId: number;
  @Input() strategyId: number;

  strategy: Strategy = new Strategy();
  numberStrategy: number;
  date: any;


  constructor(private notifyService: NotificationService, private contributionService: ContributionService) {
  }

  round(valueToRound: number): number {
    return Math.ceil(valueToRound / 10) * 10;
  }
  

  ngOnInit(): void {
    if (this.numberStrategy == 1) {
      this.cssClass = "first";
    }
    else if (this.numberStrategy == 2) {
      this.cssClass = "second";
    }

    this.LoadData(this.receivedProductId, this.strategyId);
  }

  LoadData(productId: number, strategyId: number) {
    let request: GetContributionStrategyRequest = new GetContributionStrategyRequest();
    request.ProductId = productId;
    request.StrategyId = strategyId;

    this.contributionService.GetContributionStrategy(request).subscribe({
      next: (data: Strategy) => {
        this.strategy.percentSales = this.round(data.percentSales)
        this.strategy.campaignId = data.campaignId;
        this.strategy.year = data.year;
        
        if (data.title)
          data.title = data.title.trim();

        this.strategy.title = "Récolte " + this.strategy.year + ((data.title) ? " - " + data.title : "");
        this.strategy.text = data.text;
        this.strategy.percentCall = data.percentCall;
        this.strategy.percentSales = data.percentSales;
        this.strategy.percentSalesRounded = this.round(data.percentSales);
        this.strategy.date = data.date;

        // let day = this.date.substring(0, 2);
        // let month = this.date.substring(3, 5);
        // let year = this.date.substring(6, 10);
        // this.strategyHeaderComponent.strategyDate = this.date;

        // GlobalFunctions.SetStrategyDate(year + month + day);
      },
      error: (error: { status: number; }) => {
        if (error.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }

      },
      complete: () => {
        // console.info("Strategy completed")
      }
    });
  }


}


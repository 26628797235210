import { Injectable } from '@angular/core';
import { GetContributionKeyPoints } from 'src/model/contribution/GetContributionKeyPoints';
import { GetContributionAvisRequest } from '../../../model/contribution/GetContributionAvisRequest';
import { GetContributionChartRequest } from '../../../model/contribution/GetContributionChartRequest';
import { GetContributionDecryptRequest } from '../../../model/contribution/GetContributionDecryptRequest';
import { GetContributionExpertOpinionRequest } from '../../../model/contribution/GetContributionExpertOpinionRequest';
import { GetContributionFocusRequest } from '../../../model/contribution/GetContributionFocusRequest';
import { GetContributionStrategyRequest } from '../../../model/contribution/GetContributionStrategyRequest';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})

export class ContributionService extends Service {
  override controller = 'Contribution';
  getContributionStrategyMethod: string = 'GetContributionStrategy';
  getContributionExpertOpinionMethod: string = 'GetContributionExpertOpinion';
  getContributionDecryptMethod: string = 'GetContributionDecrypt';
  getContributionFocusMethod: string = 'GetContributionFocus';
  getContributionChartMethod: string = 'GetContributionChart';
  getContributionAvisMethod: string = 'GetContributionAvis';
  getContributionKeyPoints: string = 'GetContributionKeyPoints';

  GetContributionStrategy(request: GetContributionStrategyRequest): any {
    let data: any = this.HttpPost(this.controller, this.getContributionStrategyMethod, request)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetContributionExpertOpinion(request: GetContributionExpertOpinionRequest): any {
    let data: any = this.HttpPost(this.controller, this.getContributionExpertOpinionMethod, request)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetContributionDecrypt(request: GetContributionDecryptRequest): any {
    let data: any = this.HttpPost(this.controller, this.getContributionDecryptMethod, request)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetContributionFocus(request: GetContributionFocusRequest): any {
    let data: any = this.HttpPost(this.controller, this.getContributionFocusMethod, request)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetContributionChart(request: GetContributionChartRequest): any {
    let data: any = this.HttpPost(this.controller, this.getContributionChartMethod, request)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetContributionAvis(request: GetContributionAvisRequest): any {
    let data: any = this.HttpPost(this.controller, this.getContributionAvisMethod, request)

    JSON.parse(JSON.stringify(data))

    return data;
  }
  
  GetContributionKeyPoints(request : GetContributionKeyPoints) : any{
    let data : any = this.HttpPost(this.controller, this.getContributionKeyPoints, request)

    JSON.parse(JSON.stringify(data))

    return data
  }
}




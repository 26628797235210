import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header/header.component.service';

@Component({
  selector: 'app-replay',
  templateUrl: './replay.component.html',
  styleUrls: ['./replay.component.scss']
})
export class ReplayComponent implements OnInit {

  constructor(private headerService: HeaderService) { }

  ngOnInit(): void {
    this.setHeaderTitle();
  }

  setHeaderTitle() {
    this.headerService.setTitle("Replays & Vidéos");
  }

}

<div class="focus-container ">
  <div class="focus-title-header">
    <img src="../../../assets/images/focus_icon.png" width="50" />
    <label class="focus-label">Focus</label>
  </div>
  <div class="focus-content-container">
    <div class="focus-article-name">
      {{title}}
    </div>
    <div class="focus-image-container">
      <a (click)="openDialog()" class="image-link">
        @if (focusImage!='') {
          <img class="pointer imgFocus" id="imgFocus" src="{{focusImage}}" width="500"/>
        }
      </a>
    </div>
    <div class="focus-text-container">
      <div class="focus-detail-text" [innerHTML]="text | safeHtml">
      </div>
    </div>
  </div>
</div>
<div class="alert-container">
  <div class="alert-file">
    <div class="alert-header-container">
      <h4>Mes alertes</h4>
      <a (click)="markAllRead()" class="button-read-all">Tout marquer comme lu</a>
    </div>
    <div class="alert-content-container">

      @for (item of alertsHistoryReceived; track item) {
      <div class="alert-item-container">
        <div class="alert-text-container"
          (mouseenter)=" !item.isRead && onMouseEnter(item.alertsSendedId, item.isRead) ">
          <span>
            @switch (item.alertsTypeId) {
            @case (1) {
            <h5 class="alert-header-text">
              <div class="notification-pastille" [hidden]="item.isRead"></div>
              <img src="{{ '../../../assets/images/icon-alert/flash.png' }}" alt="{{ item.labelAlertsType }}" width="32"
                class="alert-type-icon" />
              {{ item.labelAlertsType }}
            </h5>
            }
            @case (2) {
            <h5 class="alert-header-text">
              <div class="notification-pastille" [hidden]="item.isRead"></div>
              <img src="{{ '../../../assets/images/svg/cow.svg' }}" alt="{{ item.labelAlertsType }}" width="32"
                class="alert-type-icon" />
              {{ item.labelAlertsType }}
            </h5>
            }
            @case (3) {
            <h5 class="alert-header-text">
              <div class="notification-pastille" [hidden]="item.isRead"></div>
              <img src="{{ '../../../assets/images/icon-alert/epiblepenche.png' }}" alt="{{ item.labelAlertsType }}"
                width="32" class="alert-type-icon" />
              {{ item.labelAlertsType }}
            </h5>
            }
            @default {
            <h5 class="alert-header-text">
              <div class="notification-pastille" [hidden]="item.isRead"></div>
              <img src="{{'../../../assets/images/icon-alert/newsagritel.png'}}" alt="{{ item.labelAlertsType }}"
                width="32" class="alert-type-icon" />
              {{ item.labelAlertsType }}
            </h5>
            }
            }
          </span>
          <small class="alert-date">
            le {{ item.dateSend | date : "medium" }}</small>
          @if (item.link.length > 0) {
          <div>
            <p>
              {{ item.message }}.
              <a href="{{ item.link }}" target="_blank" class="alert-link">
                {{ linkName }}
              </a>
            </p>
          </div>
          } @else {
          <p>{{ item.message }}.</p>
          }
          <hr />
        </div>
      </div>
      }
      @if (alertsHistoryReceived.length <= 0) { <div>
        <p class="message-no-alert">
          Vous n'avez pas d'alertes pour l'instant.
        </p>
    </div>
    }
  </div>
</div>
<div>
  <app-alert-notifications></app-alert-notifications>
</div>
</div>
import { Cookie } from "./Cookie";
import { User } from "../tools/User";

  export class GlobalFunctionsToken {
   static token = "token"; 

    public static GetConnectionToken(): string {
      return Cookie.GlobalFunctionsCookie.GetCookie(this.token).trim();
    }

    public static SetConnectionToken(token: string) {
      Cookie.GlobalFunctionsCookie.SetCookie("token", token, 60 * 24);// 24 heures de validité

      if (token && token.trim().length > 0) {
        User.GlobalFunctionsUser.SetIsConnected(true);
      }
      else {
        User.GlobalFunctionsUser.SetIsConnected(false);
      }
    }

    public static DeleteConnectionToken() {
      this.SetConnectionToken("null")
      Cookie.GlobalFunctionsCookie.DeleteCookie(this.token);
      User.GlobalFunctionsUser.SetIsConnected(false);
    }
  }

<div class="container-plan">
  <div class="plan-container-header "
    [ngClass]="isAgritelFarmerPlus  ? 'agritel-farmer-plus-background-color' : 'agritel-farmer-background-color' ">

    <h5 class="plan-title">Mon niveau d'abonnement</h5>
  </div>
</div>
<div class="container-plan-level">
  <div class="plan-content-container">
    <div class="plan-content">

      <p class="plan-level-label"> <img [src]="urlImage" alt="Agritel Plus Logo" width="50"> - {{planName}} </p>
      <p class="plan-level-label-offer-status">Votre offre {{expiredText}} le {{datePlanExpired}}</p>
      <p class="customer-service-text">Une question concernant votre abonnement ? <br> Contactez notre équipe commerciale au : <b class="orange"> 07 66 39 29 79</b></p>
    </div>
  </div>

</div>
import { Component, Injectable, OnInit } from '@angular/core';

@Component({
  selector: 'passwordForgottenWithTeaser',
  templateUrl: './password_forgotten_with_teaser.component.html',
  styleUrls: ['./password_forgotten_with_teaser.component.scss']
})

@Injectable()
export class PasswordForgottenWithTeaserComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}

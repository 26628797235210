import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Crop } from 'src/model/analysis/Crop';
import { TabMyProductsComponent } from 'src/app/farm/tab_my_products/tab_my_products.component';
import { CompanyService } from 'src/app/services/http/company.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-analysis-nav',
  templateUrl: './analysis-nav.component.html',
  styleUrls: ['./analysis-nav.component.scss'],
})
export class AnalysisNavComponent implements OnInit {

  constructor(private companyService: CompanyService, public dialog: MatDialog) {

  }

  selectedCrop: Crop = new Crop;
  crop: Observable<Crop> = this.companyService.currentProductSubject;

  ngOnInit(): void {

    this.crop.subscribe();
    this.getCurrentCrop();

  }

  getCurrentCrop() {
    this.companyService.GetCurrentProducts().subscribe({
      error: (err: any) => {
        console.error(err)
      },
      complete: () => {},
    })
  }




  openDialogProducts() {
    let dialogRef = this.dialog.open(TabMyProductsComponent, { disableClose: true })
  }

}

<div class="divProductPrices" style="padding-right:15px;">
  <div class="row" style="height: 50px; padding-top: 10px; background-color: #FABE0B; margin-left: 0; margin-right: 0; border-top-left-radius: 10px; border-top-right-radius: 10px;">
    <div id="divTitle" class="col-sm-12" style="text-align: left; padding-left: 10px; font-size: 1.2em;">
      <label style="color: #000000; font-size: large;">
        <b>{{productName}}</b>
        <img id="imgInformation" style="margin-left: 8px;padding-bottom:3px;" src="../../../../assets/images/information.png" />
      </label>
    </div>
  </div>
  <table class="table table-striped" id="table_product_price_{{productType}}">
    <thead>
      <tr style="background-color:#F3F5F6;">
        <th scope="col" style="text-align:left;"><label style="color: #000000; font-size: small;">Date</label></th>
        <th scope="col" style="text-align:center;"><label style="color: #000000; font-size: small;">Prix (¢/b)</label></th>
        <th scope="col" style="text-align:center;"><label style="color: #000000; font-size: small;">Variation</label></th>
        <th scope="col" style="text-align:center;"><label style="color: #000000; font-size: small;">Graphique</label></th>
      </tr>
    </thead>
    <tbody class="table_product_price_summary_body" id="{{tbodyId}}">
    </tbody>
  </table>
  <style>
    img#imgInformation {
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
  </style>
</div>

import { Component, OnInit } from '@angular/core';
import { BannerService } from '../services/http/banner/banner.service';
import { Banner } from 'src/model/banner';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnInit {
  constructor(private bannerService: BannerService) { }
  banner: Banner = {
    id: 0,
    code: '',
    text: '',
    isEnabled: false
  }

  ngOnInit(): void {
    console.log("init");
    this.getBannerMessage();
  }

  getBannerMessage() {
    this.bannerService.getBannerMessage("BANNER_MESSAGE").subscribe({
      next: (value: Banner) => {
        this.banner = value;
      },
      error(err) {
        console.error(err)
      },
      complete() {
        console.log("completed")
      },
    })
  }
}

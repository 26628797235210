<div class="divPhysicalQuotes">
  <div id="loaderPhysical" class="loader hidden">
    <div class="loader-wheel"></div>
    <div class="loader-text"></div>
  </div>
  <div class="row">
    <div id="divTitlePhysical" class="col-sm-8">
      <div class="divTableTitlePhysical">
        <b>{{tableTitle}}</b>
      </div>      
      <div class="divTableIconPhysical">
        <mat-icon class="matIconInfoPhysical" aria-hidden="false" aria-label="info" fontIcon="info" (mouseover)="onImgMouseover($event)" (mouseout)="onImgMouseout($event)"></mat-icon>
      </div>
      <div id="divTooltipPhysical">
        <div id="divTooltipPricePhysical" class="pointedPhysical">
          <label id="lblTooltipPricePhysical">Prix physique mis à jour<br />du Lundi au Vendredi entre 7:00 et 9:00 CET</label>
        </div>
      </div>
    </div>
    <div id="divDateRefresh_Physical" class="col-sm-4">
      <div id="divLastRefreshLabel_Physical">
        Mis à jour le {{dateLastUpdate}}
      </div>
      <div id="divButtonRefresh_Physical" class="spin1 col-sm-3 hidden" title="Rafraîchir">
        <img class="imgRefreshQuotes" src="../../assets/images/refresh.png" (click)="RefreshPrices()" />
        <label id="lbRefreshPhysical" (click)="RefreshPrices()">Actualiser</label>
      </div>
      <div id="divDropDownNbLines_Physical" class="hidden">
        <label>Lignes</label>
        <select>
          <option>5</option>
          <option>10</option>
          <option>Max</option>
        </select>
      </div>
    </div>
  </div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col"><label>Produit</label></th>
        <th scope="col"><label>Prix (€/t)</label></th>
        <th scope="col"><label>Variation</label></th>
        <th scope="col" class="hide-mobile"><label>Graphique</label></th>
      </tr>
    </thead>
    <tbody class="table_quotes_physical_body">
      <ng-container #table_quotes_physical_body_Placeholder></ng-container>
    </tbody>
  </table>
</div>

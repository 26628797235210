import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AlertHistoryService } from "./http/alerts/alert.history.service";
import { NotificationService } from "../shared/components/notifications/notification.service";

@Injectable()
export class LeftMenuService {
  constructor(private alertHistoryService: AlertHistoryService, private notificationService: NotificationService,) { }

  notification: BehaviorSubject<number> = new BehaviorSubject(0);

  setNumberOfNotification(numberOfNotification: number) {
    this.notification.next(numberOfNotification);
  }

  getNumberOfAlertsNotRead() {
    this.alertHistoryService.GetNumberAlertHistoryNotReadForUser()
      .subscribe({
        next: (item: number) => {
          this.setNumberOfNotification(item);
        },
        error: (err: any) => {
          if (err !== 401) {
            this.notificationService.ShowError("", "Une erreur a eu lieu");
          }
        },
        complete: () => {},
      });
  }
}
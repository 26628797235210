import { HttpClient, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalFunctions, GlobalParams } from '../../../global';

@Injectable({
  providedIn: 'root'
})

export class Service {
  constructor(public http: HttpClient) {
    GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => false);
  }

  apiEndPoint: string = GlobalFunctions.GetApiEndpoint(true); 
  agritelApiEndPoint: string = GlobalFunctions.GetAgritelApiEndpoint(true); 
  controller: string = '';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  httpOptionsSecureToken = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'id': '3',
      'audience': 'Website',
      'key': GlobalParams.Key,

    })
  };


  /**
   * @deprecated 
   * 
   */

  HttpPost(controller: string, method: string, request: any, useSecureToken: boolean = false): any {
    if (useSecureToken) {
      GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => true)
      return this.http.post<any>(`${this.apiEndPoint}/${controller}/${method}`, request, this.httpOptionsSecureToken);
    }
    else {
      return this.http.post<any>(`${this.apiEndPoint}/${controller}/${method}`, request, this.httpOptions);
    }
  }


  /**
   * 
   * @param controller 
   * @param method 
   * @param request 
   * @param useSecureToken 
   * @returns 
   */

  HttpPostGeneric<T, U>(controller: string, method: string, request: T, useSecureToken: boolean = false): Observable<U> {
    if (useSecureToken) {
      GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => true)
      return this.http.post<U>(`${this.apiEndPoint}/${controller}/${method}`, request, this.httpOptionsSecureToken);
    }
    else {
      return this.http.post<U>(`${this.apiEndPoint}/${controller}/${method}`, request, this.httpOptions);
    }
  }


  /**
 * @param controller 
 * @param method 
 * @param request 
 * @param useSecureToken 
 * @returns 
 */

  HttpPostApiAgritel<T, U>(controller: string, method: string, request: T, useSecureToken: boolean = false): Observable<U> {
    if (useSecureToken) {
      GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => true)
      return this.http.post<U>(`${this.agritelApiEndPoint}/${controller}/${method}`, request, this.httpOptionsSecureToken,);
    }
    else {
      return this.http.post<U>(`${this.agritelApiEndPoint}/${controller}/${method}`, request, this.httpOptions);
    }
  }




  /**
   * 
   * @param controller 
   * @param method 
   * @param params 
   * @returns 
   */
  HttpGet(controller: string, method: string, params: any): any {
    return this.http.get(`${this.apiEndPoint}/${controller}/${method}?${params}`);
  }


}
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from 'src/app/services/http/user.service';
import { NotificationService } from '../components/notifications/notification.service';

export const subscriptionGuard: CanActivateFn = (route, state) => {

  const userService = inject(UserService); 
  const notificationService = inject(NotificationService);
  const router = inject(Router); 
  let canAccess : boolean = false; 
  userService.GetIdProfil().subscribe({

    next: (value) => {
      switch (value.idProfil) {
        case 1:
          router.navigateByUrl('agritel-plus'); 
          canAccess =  false;
          break; 
        case 2:
        case 3:
        case 4:
          canAccess =  true;
          break; 
      }
      return canAccess; 
    },
    error: (err) => {
      if (err.status !== 401) {
        notificationService.ShowError("Erreur lors du chargement des informations.", "");
      }
    },
    complete: () => {},
  })
  return true;
};

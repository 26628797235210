import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalFunctions, GlobalParams } from '../../../global';
import { LoginInformations } from '../../../model/LoginInformations';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { UserService } from '../../services/http/user.service';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password_forgotten.component.html',
  styleUrls: ['./password_forgotten.component.scss']
})

@Injectable()
export class PasswordForgottenComponent implements OnInit {

  constructor(private userService: UserService, private notificationService: NotificationService) {
  }


  passwordReset = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])

  })

  errorMessage = '';

  ngOnInit(): void {
    this.passwordReset.value.email = GlobalParams.Email;
  }

  ResetPassword() {
    if (this.passwordReset.value.email.trim().length == 0) {
      this.notificationService.ShowWarning("Veuillez saisir un email", "");
      return;
    }
    else if (!this.IsEmailCorrect()) {
      this.notificationService.ShowWarning("Le format de l'adresse email est incorrect", "");
      return;
    }

    let request: LoginInformations = new LoginInformations();

    request.Email = this.passwordReset.value.email;

    this.userService.ResetPassword(request).subscribe((data) => {
      this.notificationService.ShowSuccess("Votre mot de passe a été réinitialisé. Le nouveau mot de passe vous a été envoyé par email.", "");
    },
      err => {
        if (err.error.detail == "BadEmail") {
          this.notificationService.ShowWarning("Compte inexistant", "");
        }
        else {
          this.notificationService.ShowError("Erreur lors de la la réinitialisation du mot de passe", "");
        }
      }
    );
  }

  IsEmailCorrect(): boolean {
    return (GlobalFunctions.ValidateEmail(this.passwordReset.value.email.trim())) != null;
  };
}

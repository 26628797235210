import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header/header.component.service';

@Component({
  selector: 'app-agritel-overlay-plus',
  templateUrl: './agritel-overlay-plus.component.html',
  styleUrls: ['./agritel-overlay-plus.component.scss']
})
export class AgritelOverlayPlusComponent implements OnInit {
  constructor(private headerService: HeaderService){}

  ngOnInit(): void {
    this.setHeader(); 
  }

  setHeader(){
    this.headerService.setTitle("Agritel Farmer Plus"); 
  }
}

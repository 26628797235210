<div class="header-future-chart ">
  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close" class="icon-close"
    (click)="ClosePopup()"></mat-icon>
  <label class="lblGraphTitleFuture">{{graphTitle}}</label>
</div>
<div class="chart-container">
  <div class=" rowIntervalFuturesEuronext">
    <interval_selector pricesType="Futures" unit="{{unit}}" (eventExportGraph)="this.ExportGraph($event)"
      (eventRefreshGraph)="this.RefreshGraph($event)"></interval_selector>
  </div>
  <div id="charts" #charts></div>

</div>
import { HttpContextToken } from "@angular/common/http";
import { ViewContainerRef } from "@angular/core";
import { GlobalParams } from "../global";
import { GlobalFunctionsToken } from "../tools/Token";
import { UI } from "../tools/UI";

export namespace Connection {
  export class GlobalFunctionsConnection {
    public static Logout(): void {
      GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => false);

      const urlToBePersisted = localStorage.getItem("redirectedUrl"); 
      localStorage.clear();
      localStorage.setItem("redirectedUrl", urlToBePersisted);


      GlobalFunctionsToken.DeleteConnectionToken();

      let homeLogged_Placeholder: ViewContainerRef = UI.GlobalFunctionsUI.GetHomeLogged_Placeholder();

      if (homeLogged_Placeholder) {
        homeLogged_Placeholder.clear();
      }
    }
  }
}

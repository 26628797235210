import { Component, Output } from '@angular/core';

@Component({
  selector: 'app-durum-wheat',
  templateUrl: './durum-wheat.component.html',
  styleUrls: ['./durum-wheat.component.scss']
})
// Blé dur 
export class DurumWheatComponent {
  @Output() sentProductId: number = 8;

  classBlurred: any;

}

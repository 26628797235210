<div class="expert-opinion-container">
  <div class="expert-opinion-header">
    <img height="40" width="40" src="../../../../assets/images/icon-analysis/{{iconProduct}}" />
    <label class="expert-opinion-label">Avis de l'expert </label>
  </div>
  <div class="expert-opinion-content-container">
    <div class="expert-opinion-article-name">
      {{expertOpinion.title}}
    </div>
    <div class="expert-opinion-text-container">
      <div [innerHTML]="expertOpinion.text | safeHtml">
      </div>
    </div>

    <!-- <div class="cn">
      <div class="inner">
        <div class="firstText wrapper {{expertOpinion.classBackgroundTrendHorizon1}}">
          <div class="inner1">
            <label *ngIf="getProductId()!=9">A l'horizon&nbsp;</label>{{expertOpinion.horizon1Label}}
          </div>
          <div class="inner2">
            <img class="{{expertOpinion.classImgTrendHorizon1}}" src="../../../../assets/images/arrow.svg" />
          </div>
        </div>
        <div class="spacerText">
          <img class="imgTrend" src="../../../../assets/images/grey_arrow.svg" />
        </div>
        <div class="secondText wrapper {{expertOpinion.classBackgroundTrendHorizon2}}">
          <div class="inner1">
            <label *ngIf="getProductId()!=9">A l'horizon&nbsp;</label>{{expertOpinion.horizon2Label}}
          </div>
          <div class="inner2">
            <img class="{{expertOpinion.classImgTrendHorizon2}}" src="../../../../assets/images/arrow.svg" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
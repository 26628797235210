<div class="sticky header-connected">
  <div class="title-img-container">
    <div class="logo-img">
      <a routerLink="/cotations" routerLinkActive="active-link">
        <img [src]="logoUrl" alt="Logo agritel" width="60">
      </a>
    </div>
    <p class="header-title">{{title}}</p>
    <div class="user-menu">
      <user-menu></user-menu>
    </div>
  </div>
</div>
<div class="faq-container">
  <mat-accordion class="headers-align" multi>
    @for (theme of allThemeAndItems; track theme;) {

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="header-theme">
          {{theme.name}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      @for (item of theme.items; track item) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="title-question">

            <p>{{item.title}}</p>
          </mat-panel-title>
          <mat-divider></mat-divider>
        </mat-expansion-panel-header>
        <p>{{item.text}}</p>
        @if (item.imageUrl != null) {
          <img src="../../{{item.imageUrl}}" alt="">
        }
      </mat-expansion-panel>
      }
    </mat-expansion-panel>
    }
  </mat-accordion>
</div>
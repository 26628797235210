<div class="user-menu-container">
  <div class="user-menu-trigger-container">
    <button mat-button [matMenuTriggerFor]="menu" class="user-menu-trigger-container mobile">
      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="menu" class="menu-mobile"></mat-icon>
    </button>

    <button mat-button [matMenuTriggerFor]="menu" class="user-menu-trigger-container web">
      <img class="imgArrowDown toggle-down" src="assets/images/arrow-down-white.png" />
      <label class="user-menu-name">{{GetName()}}</label>
      <img src="assets/images/userIcone.svg" class="imgUserIcone" width="40" />
    </button>
  </div>
  <mat-menu #menu="matMenu" class="user-menu-trigger-container" backdropClass="user-menu-trigger-container">
    <div class="user-menu-content">
      <a mat-menu-item [routerLink]="['/compte/informations']" class="user-menu">Mon compte</a>
      <!-- <a [routerLink]="['compte/abonnement']" class="user-menu">Mon abonnement</a> -->
      <a mat-menu-item [routerLink]="['/compte/rapports']" class="user-menu">Mes rapports</a>
      <a mat-menu-item [routerLink]="['/aide']" class="user-menu hide-desktop">Aide</a>

      <button type="button" (click)="Logout()" class="btn buttonNormalcaseOrange button-logout ">Déconnexion</button>
    </div>

  </mat-menu>

</div>
import { HttpContextToken } from "@angular/common/http";
import { ViewContainerRef } from "@angular/core";
import { Company } from "./model/Company";

export { };

export namespace GlobalEnums {

  export enum TypeProductEnum {
    bleTendre = 1,
    mais,
    colza,
    ourgeFourragere,
    orgeBrassPrin,
    orgeBrassHiv,
    tournesol,
    bleDur,
    engrais,
    other
  }
}

export class GlobalParams {

  public static Audience: string = 'https://my.captainfarmer.com/web/';
  public static ImagesFolder: string = 'assets/images/';
  public static Key: string = 'gB_u`Pk@JcV,WSqj38u7C;L8Zrx&DyV<FYvzh$^';
  public static AgritelAPIToken : string = "m*K-2R;=Ewp1EDwu7<*zmxI<'=NQC0"; 
  public static EmptyGuid: string = '00000000-0000-0000-0000-000000000000';
  public static SubscriptionPageURL: string = 'https://alabordage.captainfarmer.com/inscription-2';
  public static Email: string = '';
  public static FirstName: string = '';
  public static LastName: string = '';
  public static IsFirstConnection: boolean = false;
  public static DefaultProducts: number;
  public static IsNotifyInformationAccepted: boolean = false;
  public static IsConnected: boolean = false;
  public static Company: Company = new Company();
  public static WebsiteBaseURL: string = '';
  public static SubscribedPlanId: number;
  public static ApiEndpoint: string = '';
  public static AgritelApiEndpoint : string = ''; 
  public static AdvisorsImagesUrl: string = '';
  public static AnalysisImagesUrl: string = '';
  public static LogUserExperience: boolean = false;
  public static ShowToggleTheme: boolean = false;
  public static ForceHttps: boolean = false;  
  public static Environment: string = '';
  public static IdPlatform: number = 2;
  public static ProductId: GlobalEnums.TypeProductEnum;
  public static ProductImage: string;
  public static StrategyDate: string;
  public static updateCompanyPaymentInformations: string = 'UpdateCompanyPaymentInformations';  
  public static addSecureTokenForUser: string = 'AddSecureTokenForUser';
  public static checkSecureToken: string = 'CheckSecureToken';
  public static changePassword: string = 'ChangePassword';
  public static BYPASS_SPINNER = new HttpContextToken(() => false);
  public static BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => false);
  public static CookiePath = 'testweb';
  public static homeLogged_Placeholder: ViewContainerRef;
  public static AdvisorInformationAgritelFarmer : { 
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  } ; 

  public static AdvisorInformationAgritelFarmerPlus : { 
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  } ; 

}

export class GlobalFunctions {  
  public static PositiveOrNegative() {
    return Math.round(Math.random()) * 2 - 1;
  }

  public static RandomNumberInRange(start, end) {
    if (start > end)
      start++;
    else
      end++;

    return Math.floor((start + Math.random() * (end - start)));
  }

  public static CapitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public static GetProductNameFromCode(productCode: string, jsonGetProductQuotes) {
    let productName: string = '';

    if (jsonGetProductQuotes && jsonGetProductQuotes.length > 0) {
      jsonGetProductQuotes.forEach(product => {
        if (product.importCode == productCode) {
          productName = product.nameProduct.replace("CME", "").replace("Euronext", "");
        }
      });
    }

    return productName;
  }

  public static FindProductMultiplier(productCodesUnitsMultipiersTooltips: Array<string>, currentProductCode: string) {
    let multiplier: number;

    productCodesUnitsMultipiersTooltips.forEach(productCodeUnitMultiplierTooltip => {
      var productCodeAndUnitMultiplierSplitted = productCodeUnitMultiplierTooltip.split(';');
      var productCode = productCodeAndUnitMultiplierSplitted[0];
      var productMultiplier = productCodeAndUnitMultiplierSplitted[3];

      if (productCode == currentProductCode) {
        multiplier = parseInt(productMultiplier);
      }
    });

    return multiplier;
  }



  public static GetEnvironment(): string {
    return GlobalParams.Environment;
  }

  public static SetProductId(productId: GlobalEnums.TypeProductEnum) {
    GlobalParams.ProductId = productId;
  }

  public static GetProductId(): GlobalEnums.TypeProductEnum {
    return GlobalParams.ProductId;
  }

  public static SetProductImage(productImage: string) {
    GlobalParams.ProductImage = productImage;
  }

  public static GetProductImage(): string {
    return GlobalParams.ProductImage;
  }

  public static GetPlanNameFromId(planId: number): string {
    let planName: string = '';

    switch (planId) {
      case 4:
      case 2:
      case 1:
        planName = "Agritel Farmer";
        break;
      case 3:
        planName = "Agritel Farmer";
        break;
    }

    return planName;
  }

  public static SetStrategyDate(date: string) {
    GlobalParams.StrategyDate = date;
  }

  public static GetStrategyDateFormatted(): string {
    let strategyDateFormatted: string = '';

    if (GlobalParams.StrategyDate)
      strategyDateFormatted = GlobalParams.StrategyDate.substring(6, 8) + "/" + GlobalParams.StrategyDate.substring(4, 6) + "/" + GlobalParams.StrategyDate.substring(0, 4);   

    return strategyDateFormatted;
  }

  public static GetStrategyDate(): string {
    return GlobalParams.StrategyDate;
  }

  public static SetSubscribedPlanId(subscribedPlanId: number) {
    localStorage.setItem('subscribedPlanId', subscribedPlanId.toString());
    GlobalParams.SubscribedPlanId = subscribedPlanId;
  }

  public static GetSubscribedPlanId(): number {
    return +localStorage.getItem('subscribedPlanId');
  }

  public static SetWebsiteBaseURL(websiteBaseURL: string) {
    GlobalParams.WebsiteBaseURL = websiteBaseURL;
  }

  public static GetWebsiteBaseURL() {
    return GlobalParams.WebsiteBaseURL;
  }

  public static SetApiEndpoint(apiEndpoint: string) {
    GlobalParams.ApiEndpoint = apiEndpoint;
  }

  public static GetApiEndpoint(removeLastBackSlash: boolean) {
    if (removeLastBackSlash && GlobalParams.ApiEndpoint.endsWith('/'))
      this.SetApiEndpoint(GlobalParams.ApiEndpoint.slice(0, -1));    

    return GlobalParams.ApiEndpoint;
  }

  public static SetAgritelApiEndpoint(agritelApiEndpoint: string) {
    GlobalParams.AgritelApiEndpoint = agritelApiEndpoint;
  }


  public static GetAgritelApiEndpoint(removeLastBackSlash: boolean) {
    if (removeLastBackSlash && GlobalParams.AgritelApiEndpoint.endsWith('/'))
      this.SetAgritelApiEndpoint(GlobalParams.AgritelApiEndpoint.slice(0, -1));    

    return GlobalParams.AgritelApiEndpoint;
  }

  public static SetAdvisorsImagesUrl(advisorsImagesUrl: string) {
    GlobalParams.AdvisorsImagesUrl = advisorsImagesUrl;
  }

  public static GetAdvisorsImagesUrl() {
    return GlobalParams.AdvisorsImagesUrl;
  }

  public static SetAnalysisImagesUrl(analysisImagesUrl: string) {
    GlobalParams.AnalysisImagesUrl = analysisImagesUrl;
  }

  public static GetShowToggleTheme(): boolean {
    return GlobalParams.ShowToggleTheme;
  }

  public static SetForceHttps(forceHttps: boolean) {
    GlobalParams.ForceHttps = forceHttps;
  }

  public static GetForceHttps(): boolean {
    return GlobalParams.ForceHttps;
  }

  public static SetEnvironment(environment: string) {
    GlobalParams.Environment = environment;
  }

  public static SetShowToggleTheme(showToggleTheme: boolean) {
    GlobalParams.ShowToggleTheme = showToggleTheme;
  }

  public static SetAdvisorInformationAgritelFarmer(advisorInformationAgritelFarmer : { 
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  }){
    GlobalParams.AdvisorInformationAgritelFarmer = advisorInformationAgritelFarmer
  }

  public static GetAdvisorInformationAgritelFarmer(){
    return GlobalParams.AdvisorInformationAgritelFarmer
  }

  public static SetAdvisorInformationAgritelFarmerPlus(advisorInformationAgritelFarmerPlus :  { 
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  }){
    GlobalParams.AdvisorInformationAgritelFarmerPlus = advisorInformationAgritelFarmerPlus
  }

  public static GetAdvisorInformationAgritelFarmerPlus(){
    return GlobalParams.AdvisorInformationAgritelFarmerPlus
    
  }

  public static GetLogUserExperience(): boolean {
    return GlobalParams.LogUserExperience;
  }

  public static SetLogUserExperience(logUserExperience: boolean) {
    GlobalParams.LogUserExperience = logUserExperience;
  }

  public static GetAnalysisImagesUrl() {
    return GlobalParams.AnalysisImagesUrl;
  }


  public static ValidateEmail = (email: string): RegExpMatchArray | null => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
}

<div class="header-physical-chart">
  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close" class="icon-close" (click)="ClosePopup()" ></mat-icon>
  <label class="lblGraphTitlePhysical">{{graphTitle}}</label>

</div>
<div class="physical-price-chart-container">
  <div class=" rowIntervalPhysical">
    <interval_selector pricesType="Physical" unit="{{unit}}" (eventExportGraph)="this.ExportGraph($event)" (eventRefreshGraph)="this.RefreshGraph($event)"></interval_selector>
  </div>
  <div id="charts" #charts></div>
</div>


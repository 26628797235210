<nav class="main-nav">
  <!-- Menu Nav -->
  <ul class="menu-items">
    <li class="logo-left-menu">
      <a routerLink="/cotations" routerLinkActive="active-link">
        <img [src]="logoUrl" alt="Logo Agritel" width="100" class="logo-agritel">
      </a>
    </li>
    <li>
      <a routerLink="/cotations" title="Cotations" routerLinkActive="active-link">
        <svg xmlns="http://www.w3.org/2000/svg" width="50.163" height="28.448" viewBox="0 0 50.163 28.448"
          class="svg-icon">
          <g id="Groupe_1091" data-name="Groupe 1091">
            <g id="noun_Wheat_2021426" data-name="noun Wheat 2021426" transform="translate(21.329 22.668)">
              <g id="Groupe_183" data-name="Groupe 183">
                <g id="Groupe_182" data-name="Groupe 182">
                  <path id="Tracé_498" data-name="Tracé 498"
                    d="M164.712,935.278a.722.722,0,0,0-.72.722v.01l.017,4.328a.721.721,0,0,0,.721.72h.01a.7.7,0,0,0,.722-.684.342.342,0,0,0,0-.047L165.445,936A.739.739,0,0,0,164.712,935.278Z"
                    transform="translate(-163.992 -935.278)" />
                </g>
              </g>
            </g>
            <path id="Tracé_691" data-name="Tracé 691"
              d="M158.079,934.387l-.606.655a1.063,1.063,0,0,1-1.392.146l-3.631-2.568a1.17,1.17,0,0,0-1.5.11l-6.569,6.4a1.057,1.057,0,0,0-.019,1.495h0a1.057,1.057,0,0,0,1.495.019l5.3-5.161a1.168,1.168,0,0,1,1.5-.11l3.933,2.846a.4.4,0,0,0,.54-.061l1.593-1.817,1.455-1.586a1.113,1.113,0,0,0,.292-.752v-2.35Z"
              transform="translate(-144.059 -912.864)" />
            <path id="Tracé_265" data-name="Tracé 265"
              d="M190.641,922.7l-3.78,3.67a1.039,1.039,0,0,1-1.53-.092l-7.8-9.64a1.039,1.039,0,0,0-1.495-.124l-5.628,4.978a1.039,1.039,0,0,0-.351.778v2.3l5.732-5.064a1.037,1.037,0,0,1,1.494.124l7.867,9.719a1.038,1.038,0,0,0,1.53.091l5.41-5.253a1.037,1.037,0,0,0,.024-1.466h0A1.037,1.037,0,0,0,190.641,922.7Z"
              transform="translate(-142.239 -913.943)" />
            <g id="Groupe_1087" data-name="Groupe 1087" transform="translate(18.023)">
              <g id="Groupe_1086" data-name="Groupe 1086">
                <g id="Groupe_1085" data-name="Groupe 1085">
                  <path id="Tracé_692" data-name="Tracé 692"
                    d="M163.6,924.948h.538v-1.616a2.693,2.693,0,0,0-2.693-2.693H160.9v1.616A2.694,2.694,0,0,0,163.6,924.948Z"
                    transform="translate(-160.903 -913.635)" />
                  <path id="Tracé_693" data-name="Tracé 693"
                    d="M163.6,929.48h.538v-1.617a2.693,2.693,0,0,0-2.693-2.693H160.9v1.616A2.694,2.694,0,0,0,163.6,929.48Z"
                    transform="translate(-160.903 -913.318)" />
                  <path id="Tracé_694" data-name="Tracé 694"
                    d="M164.136,932.394a2.693,2.693,0,0,0-2.693-2.693H160.9v1.616a2.694,2.694,0,0,0,2.694,2.694h.538Z"
                    transform="translate(-160.903 -913.001)" />
                  <path id="Tracé_695" data-name="Tracé 695"
                    d="M165.435,923.332v1.616h.538a2.693,2.693,0,0,0,2.693-2.693v-1.616h-.538A2.693,2.693,0,0,0,165.435,923.332Z"
                    transform="translate(-160.586 -913.635)" />
                  <path id="Tracé_696" data-name="Tracé 696"
                    d="M165.435,927.863v1.617h.538a2.694,2.694,0,0,0,2.693-2.694V925.17h-.538A2.693,2.693,0,0,0,165.435,927.863Z"
                    transform="translate(-160.586 -913.318)" />
                  <path id="Tracé_697" data-name="Tracé 697"
                    d="M165.435,932.394v1.617h.538a2.694,2.694,0,0,0,2.693-2.694V929.7h-.538A2.693,2.693,0,0,0,165.435,932.394Z"
                    transform="translate(-160.586 -913.001)" />
                  <path id="Tracé_698" data-name="Tracé 698"
                    d="M161.712,919.879a.808.808,0,0,0,.808-.808v-2.155a.808.808,0,0,0-1.617,0v2.155A.808.808,0,0,0,161.712,919.879Z"
                    transform="translate(-160.903 -913.953)" />
                  <path id="Tracé_699" data-name="Tracé 699"
                    d="M167.753,916.108a.808.808,0,0,0-.808.808v2.155a.808.808,0,1,0,1.616,0v-2.155A.808.808,0,0,0,167.753,916.108Z"
                    transform="translate(-160.48 -913.953)" />
                  <path id="Tracé_700" data-name="Tracé 700"
                    d="M164.732,921.636a.809.809,0,0,0,.809-.808V914.9a.808.808,0,0,0-1.617,0v5.926A.808.808,0,0,0,164.732,921.636Z"
                    transform="translate(-160.691 -914.094)" />
                </g>
              </g>
            </g>
            <g id="Groupe_1090" data-name="Groupe 1090" transform="translate(30.09 10.531)">
              <g id="Groupe_1089" data-name="Groupe 1089">
                <g id="Groupe_1088" data-name="Groupe 1088">
                  <path id="Tracé_701" data-name="Tracé 701"
                    d="M174.328,932.592h.429V931.3a2.147,2.147,0,0,0-2.148-2.148h-.429v1.288A2.148,2.148,0,0,0,174.328,932.592Z"
                    transform="translate(-172.18 -923.57)" />
                  <path id="Tracé_702" data-name="Tracé 702"
                    d="M174.328,936.2h.429v-1.288a2.148,2.148,0,0,0-2.148-2.149h-.429v1.289A2.147,2.147,0,0,0,174.328,936.2Z"
                    transform="translate(-172.18 -923.317)" />
                  <path id="Tracé_703" data-name="Tracé 703"
                    d="M174.757,938.53a2.148,2.148,0,0,0-2.148-2.149h-.429v1.289a2.148,2.148,0,0,0,2.148,2.148h.429Z"
                    transform="translate(-172.18 -923.064)" />
                  <path id="Tracé_704" data-name="Tracé 704"
                    d="M175.793,931.3v1.289h.429a2.149,2.149,0,0,0,2.149-2.149v-1.288h-.43A2.148,2.148,0,0,0,175.793,931.3Z"
                    transform="translate(-171.927 -923.57)" />
                  <path id="Tracé_705" data-name="Tracé 705"
                    d="M175.793,934.917V936.2h.429a2.148,2.148,0,0,0,2.149-2.148v-1.289h-.43A2.148,2.148,0,0,0,175.793,934.917Z"
                    transform="translate(-171.927 -923.317)" />
                  <path id="Tracé_706" data-name="Tracé 706"
                    d="M175.793,938.53v1.288h.429a2.148,2.148,0,0,0,2.149-2.148v-1.289h-.43A2.148,2.148,0,0,0,175.793,938.53Z"
                    transform="translate(-171.927 -923.064)" />
                  <path id="Tracé_707" data-name="Tracé 707"
                    d="M172.824,928.549a.644.644,0,0,0,.644-.644v-1.719a.644.644,0,0,0-1.288,0V927.9A.643.643,0,0,0,172.824,928.549Z"
                    transform="translate(-172.18 -923.823)" />
                  <path id="Tracé_708" data-name="Tracé 708"
                    d="M177.641,925.542a.645.645,0,0,0-.644.644V927.9a.645.645,0,0,0,1.289,0v-1.719A.645.645,0,0,0,177.641,925.542Z"
                    transform="translate(-171.843 -923.823)" />
                  <path id="Tracé_709" data-name="Tracé 709"
                    d="M175.233,929.951a.645.645,0,0,0,.644-.645V924.58a.645.645,0,0,0-1.289,0v4.725A.645.645,0,0,0,175.233,929.951Z"
                    transform="translate(-172.011 -923.936)" />
                </g>
              </g>
            </g>
          </g>
        </svg>

        <p class="title-navigation">Cotations</p>
      </a>
    </li>

    <li>
      <a routerLink="/quotidienne" title="Quotidienne" routerLinkActive="active-link">
        <svg  height="40px" viewBox="0 -960 960 960" width="40px" class="svg-icon" ><path d="M560-570.67v-54.66q33-14 67.5-21t72.5-7q26 0 51 4t49 10v50.66q-24-9-48.5-13.5t-51.5-4.5q-38 0-73 9.5t-67 26.5Zm0 220V-406q33-13.67 67.5-20.5t72.5-6.83q26 0 51 4t49 10v50.66q-24-9-48.5-13.5t-51.5-4.5q-38 0-73 9t-67 27Zm0-110v-54.66q33-14 67.5-21t72.5-7q26 0 51 4t49 10v50.66q-24-9-48.5-13.5t-51.5-4.5q-38 0-73 9.5t-67 26.5Zm-308 154q51.38 0 100.02 11.84Q400.67-283 448-259.33v-416q-43.67-28-94.08-43t-101.92-15q-37.33 0-73.5 8.66Q142.33-716 106.67-702v421.33Q139-294 176.83-300.33q37.84-6.34 75.17-6.34Zm262.67 47.34q48-23.67 94.83-35.5 46.83-11.84 98.5-11.84 37.33 0 75.83 6t69.5 16.67v-418q-33.66-16-70.71-23.67-37.05-7.66-74.62-7.66-51.67 0-100.67 15t-92.66 43v416ZM481.33-160q-50-38-108.66-58.67Q314-239.33 252-239.33q-38.36 0-75.35 9.66-36.98 9.67-72.65 25-22.4 11-43.2-2.33Q40-220.33 40-245.33v-469.34q0-13.66 6.5-25.33Q53-751.67 66-758q43.33-21.33 90.26-31.67Q203.19-800 252-800q61.33 0 119.5 16.33 58.17 16.34 109.83 49.67 51-33.33 108.5-49.67Q647.33-800 708-800q48.58 0 95.29 10.33Q850-779.33 893.33-758q13 6.33 19.84 18 6.83 11.67 6.83 25.33v469.34q0 26.26-21.5 39.96t-43.17.7q-35-16-71.98-25.33-36.99-9.33-75.35-9.33-62 0-119.33 21-57.34 21-107.34 58.33Zm-204-330.67Z"/></svg>
        <p class="title-navigation">Quotidienne</p>
      </a>
    </li>

    <li>
      <a routerLink="/analyses" title="Analyses" routerLinkActive="active-link">
        <svg width="34" height="34" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon">

          <path
            d="M15.4859 6.77268L9.8021 9.09728C9.47214 9.23756 9.20941 9.50029 9.06913 9.83025L6.74755 15.511C6.64591 15.7515 6.61802 16.0167 6.66743 16.2731C6.71684 16.5295 6.84131 16.7653 7.02506 16.9508C7.27547 17.1941 7.61096 17.3301 7.96012 17.3299C8.13318 17.3353 8.30517 17.3009 8.46284 17.2293L14.1466 14.9047C14.4766 14.7645 14.7393 14.5017 14.8796 14.1718L17.2042 8.48797C17.3071 8.2477 17.3357 7.98207 17.2861 7.72542C17.2365 7.46876 17.1112 7.23285 16.9262 7.04818C16.7412 6.8635 16.505 6.73854 16.2483 6.68943C15.9916 6.64032 15.726 6.66932 15.4859 6.77268ZM12 13.6188C11.6803 13.6188 11.3677 13.524 11.1019 13.3464C10.836 13.1688 10.6288 12.9163 10.5064 12.6209C10.384 12.3256 10.3519 12.0006 10.4142 11.687C10.4765 11.3734 10.6304 11.0853 10.8564 10.8591C11.0824 10.633 11.3704 10.4789 11.684 10.4164C11.9975 10.3539 12.3226 10.3858 12.618 10.508C12.9135 10.6302 13.166 10.8373 13.3438 11.1031C13.5216 11.3688 13.6166 11.6813 13.6168 12.001C13.6136 12.4287 13.4422 12.838 13.1397 13.1404C12.8371 13.4427 12.4277 13.6139 12 13.6168V13.6188Z" />
          <path
            d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9971 8.8183 22.7318 5.76775 20.482 3.51795C18.2322 1.26815 15.1817 0.00292839 12 0ZM12.8838 22.1811V19.6062C12.8838 19.3717 12.7906 19.1467 12.6248 18.9809C12.459 18.8151 12.234 18.7219 11.9995 18.7219C11.765 18.7219 11.54 18.8151 11.3742 18.9809C11.2084 19.1467 11.1152 19.3717 11.1152 19.6062V22.2083C8.71784 21.9925 6.47275 20.9403 4.773 19.236C3.07325 17.5317 2.02716 15.2837 1.81785 12.8858H4.42397C4.6585 12.8858 4.88342 12.7926 5.04925 12.6268C5.21509 12.461 5.30826 12.236 5.30826 12.0015C5.30826 11.767 5.21509 11.5421 5.04925 11.3762C4.88342 11.2104 4.6585 11.1172 4.42397 11.1172H1.81886C2.02489 8.72052 3.0706 6.47355 4.77158 4.77258C6.47255 3.0716 8.71952 2.02589 11.1162 1.81986V4.42396C11.1162 4.65849 11.2094 4.88341 11.3752 5.04925C11.5411 5.21509 11.766 5.30825 12.0005 5.30825C12.235 5.30825 12.46 5.21509 12.6258 5.04925C12.7916 4.88341 12.8848 4.65849 12.8848 4.42396V1.81885C15.2815 2.02489 17.5285 3.0706 19.2294 4.77157C20.9304 6.47254 21.9761 8.71951 22.1822 11.1162H19.6062C19.3717 11.1162 19.1468 11.2094 18.9809 11.3752C18.8151 11.541 18.7219 11.766 18.7219 12.0005C18.7219 12.235 18.8151 12.46 18.9809 12.6258C19.1468 12.7916 19.3717 12.8848 19.6062 12.8848H22.2083C21.9922 15.2823 20.9397 17.5274 19.2349 19.227C17.5302 20.9266 15.2819 21.9723 12.8838 22.1811Z" />

        </svg>
        <p class="title-navigation">Analyses</p>
      </a>
    </li>

    <li>
      <a routerLink="/replay" title="Replay" routerLinkActive="active-link">
        <svg width="38" height="38" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
          routerLinkActive="active-link" id="video">
          <g id="SVGRepo_iconCarrier">
            <path d="M19.5617 7C19.7904 5.69523 18.7863 4.5 17.4617 4.5H6.53788C5.21323 4.5 4.20922 5.69523 4.43784 7"
              stroke-width="1.5" />
            <path
              d="M17.4999 4.5C17.5283 4.24092 17.5425 4.11135 17.5427 4.00435C17.545 2.98072 16.7739 2.12064 15.7561 2.01142C15.6497 2 15.5194 2 15.2588 2H8.74099C8.48035 2 8.35002 2 8.24362 2.01142C7.22584 2.12064 6.45481 2.98072 6.45704 4.00434C6.45727 4.11135 6.47146 4.2409 6.49983 4.5"
              stroke-width="1.5" />
            <path
              d="M14.5812 13.6159C15.1396 13.9621 15.1396 14.8582 14.5812 15.2044L11.2096 17.2945C10.6669 17.6309 10 17.1931 10 16.5003L10 12.32C10 11.6273 10.6669 11.1894 11.2096 11.5258L14.5812 13.6159Z"
              stroke-width="1.5" />
            <path
              d="M2.38351 13.793C1.93748 10.6294 1.71447 9.04765 2.66232 8.02383C3.61017 7 5.29758 7 8.67239 7H15.3276C18.7024 7 20.3898 7 21.3377 8.02383C22.2855 9.04765 22.0625 10.6294 21.6165 13.793L21.1935 16.793C20.8437 19.2739 20.6689 20.5143 19.7717 21.2572C18.8745 22 17.5512 22 14.9046 22H9.09536C6.44881 22 5.12553 22 4.22834 21.2572C3.33115 20.5143 3.15626 19.2739 2.80648 16.793L2.38351 13.793Z"
              stroke-width="1.5" />
          </g>

        </svg>
        <p class="title-navigation">Replays & Vidéos</p>
      </a>
    </li>

    <li class="alerte-badge">
      <a routerLink="/compte/alertes"  title="Alertes" routerLinkActive="active-link">
        <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
          transform="rotate(0)" id="notification" routerLinkActive="active-link" >
          <path
            d="M18.7491 9.70957V9.00497C18.7491 5.13623 15.7274 2 12 2C8.27256 2 5.25087 5.13623 5.25087 9.00497V9.70957C5.25087 10.5552 5.00972 11.3818 4.5578 12.0854L3.45036 13.8095C2.43882 15.3843 3.21105 17.5249 4.97036 18.0229C9.57274 19.3257 14.4273 19.3257 19.0296 18.0229C20.789 17.5249 21.5612 15.3843 20.5496 13.8095L19.4422 12.0854C18.9903 11.3818 18.7491 10.5552 18.7491 9.70957Z"
            stroke-width="1.8"></path>
          <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19" stroke-width="1.8"
            stroke-linecap="round"></path>
        </svg>
        <small class="badge" id="badge"> {{numberOfNotification}}</small>
        <p class="title-navigation">Alertes</p>
      </a>
    </li>
  </ul>
  <ul class="menu-end" class="hide-mobile">
    <li>
    <li id="space-middle" class="hide-mobile">
    </li>
    <li id="space-end" class="hide-mobile">
      <a routerLink="/aide" title="Aide" routerLinkActive="active-link">
        <svg id="noun_help_2333234" xmlns="http://www.w3.org/2000/svg" width="34" height="34"
          viewBox="0 0 22.411 22.408" class="svg-icon">
          <path id="Tracé_287" data-name="Tracé 287"
            d="M23.635,22.134a6,6,0,0,1-3.272-2.174A10.547,10.547,0,1,0,16.2,22.683a9.49,9.49,0,0,0,5.748,1.725,9.36,9.36,0,0,0,1.524-.112,1.114,1.114,0,0,0,.168-2.162ZM12.644,18.806a1.681,1.681,0,1,1,1.681-1.681A1.678,1.678,0,0,1,12.644,18.806Zm0-4.482a1.12,1.12,0,1,1,0-2.241A1.681,1.681,0,1,0,10.963,10.4a1.12,1.12,0,0,1-2.241,0,3.921,3.921,0,1,1,3.921,3.921Z"
            transform="translate(-2 -2)" />
        </svg>
        <p routerLinkActive="active-link" class="title-navigation"> Besoin d'aide ?</p>
      </a>
    </li>
  </ul>

</nav>
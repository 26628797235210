import { GlobalParams } from '../global';

export namespace User {
  export class GlobalFunctionsUser {
    public static SetIsFirstConnection(isFirstConnection: boolean) {
      GlobalParams.IsFirstConnection = isFirstConnection;
    }

    public static GetIsFirstConnection(): boolean {
      return GlobalParams.IsFirstConnection;
    }

    public static GetDefaultProducts(): number {
      return GlobalParams.DefaultProducts;
    }

    public static GetIsNotifyInformationAccepted(): boolean {
      return GlobalParams.IsNotifyInformationAccepted;
    }

    public static SetIsNotifyInformationAccepted(isNotifyInformationAccepted: boolean) {
      GlobalParams.IsNotifyInformationAccepted = isNotifyInformationAccepted;
    }

    public static SetDefaultProducts(products: number) {
      GlobalParams.DefaultProducts = products;
    }

    public static DeleteIdProfil() {
      localStorage.removeItem("IdProfil");
    }


    public static DeleteFirstName() {
      localStorage.removeItem("FirstName");
    }

    public static DeleteLastName() {
      localStorage.removeItem("LastName");
    }

    public static SetIsConnected(value: boolean) {
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem("IsConnected", value.toString());
      }
    }

    public static GetIsConnected(): boolean {
      return localStorage.getItem("IsConnected") === 'true';
    }

    public static GetFirstName(): string {
      return localStorage.getItem("FirstName");
    }

    public static GetLastName(): string {
      return localStorage.getItem("LastName");
    }

    public static GetPhoneNumber(): string {
      return localStorage.getItem("PhoneNumber");
    }

    public static SetFirstName(value: string) {
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem("FirstName", value);
      }
    }

    public static SetLastName(value: string) {
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem("LastName", value);
      }
    }

    public static SetPhoneNumber(value: string) {
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem("PhoneNumber", value);
      }
    }

    public static GetEmail(): string {
      return localStorage.getItem("Email");
    }

    public static SetEmail(email: string) {
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem("Email", email);
      }
    }
  }
}

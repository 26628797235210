import { Injectable } from '@angular/core';
import { Company } from '../../../model/Company';
import { Service } from './service';
import { BehaviorSubject } from 'rxjs';
import { Crop } from 'src/model/analysis/Crop';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class CompanyService extends Service {
  override controller = 'Company';
  setCurrentProductsMethod: string = 'SetCurrentProducts';
  getCurrentProductsMethod: string = 'GetCurrentProducts';
  getCompanyMethod: string = 'GetCompany';
  saveCompanyMethod: string = 'SaveCompany';
  setFirstConnectionMethod: string = 'SetFirstConnection';
  acceptNoticeInformationMethod: string = 'AcceptNoticeInformation';
  hasAcceptedNoticeInformationMethod: string = 'HasAcceptedNoticeInformation';
  getPlanMethod: string = 'GetPlan';
  getRightEndMethod: string = 'GetRightEnd';
  planHasExpired: string = 'PlanHasExpired';

currentProductSubject = new BehaviorSubject<Crop>(<Crop>({
    bleTendre: false,
    mais: false,
    colza: false,
    ourgeFourragere: false,
    orgeBrassPrin: false,
    orgeBrassHiv: false,
    tournesol: false,
    bleDur: false,
    engrais: false
  }))

  



  ngOnInit(): void {

  }


  SetCurrentProducts(products: any): any {
    let data: any = this.HttpPostGeneric(this.controller, this.setCurrentProductsMethod, products);
    this.currentProductSubject.next(products); 
    
    return data;
  }

  GetCurrentProducts(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.getCurrentProductsMethod, null); 
    data.subscribe({
      next:(value: Crop)=>{
        this.currentProductSubject.next(value); 

      }
    })

    return data;
  }

  GetCompany(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.getCompanyMethod, null)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  SaveCompany(company: Company): any {
    let data: any = this.HttpPostGeneric(this.controller, this.saveCompanyMethod, company)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  SetFirstConnection(request: any): any {
    let data: any = this.HttpPostGeneric(this.controller, this.setFirstConnectionMethod, request);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  AcceptNoticeInformation(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.acceptNoticeInformationMethod, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  HasAcceptedNoticeInformation(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.hasAcceptedNoticeInformationMethod, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetPlan(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.getPlanMethod, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetRightEnd(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.getRightEndMethod, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  PlanHasExpired(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.planHasExpired, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }
}



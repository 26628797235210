<tr [style]="bgColor">
  <td [style]="lastRowBorderLeft">
    <label class="label-physical-line">{{productName}}</label>
  <td class="tdPrice">
    <label id="{{'lblPrice_'+ i}}" class="lblPrice">{{line.value.toFixed(2)}}</label>
  <td class="tdPrice">
    <div class="variation-container" [style.backgroundColor]="backgroundColor">
      <label id="{{'lblVariationSign_' + i}}" class="lblPriceVariationSign">{{variationSign}}</label>
      <label id="{{'lblVariationValue_' + i}}" class="lblPriceVariation">{{line.variation}}</label>
    </div>
  <td class="graphic-icon">
    <mat-icon class="imgProductPricePhy material-icons-chart hide-mobile" (click)="OpenDialogChartPhysical()"
      aria-hidden="false" aria-label="insert_chart" title="Graphique" fontIcon="insert_chart"></mat-icon>
  </td>
</tr>
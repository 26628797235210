import { Service } from "../service";
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})

export class AlertHistoryService extends Service {
  alertHistoryController: string = "AlertHistory";
  alertsHistoryByUser: string = "GetAlertsHistory";
  alertHistoryNumber: string = "GetNumberAlertsHistoryNotRead";
  updateAlertyRead: string = "UpdateIsRead";
  updateAllHistoryIsReadForUser: string = "UpdateAllHistoryIsReadForUser"


  GetAlertHistoryForUser(defaultNumberOfAlerts: number = 10): any {
    let data: any = this.HttpPostGeneric(this.alertHistoryController, this.alertsHistoryByUser, defaultNumberOfAlerts);
    JSON.parse(JSON.stringify(data));
    return data;
  }

  GetNumberAlertHistoryNotReadForUser() {
    let data: any = this.HttpPostGeneric(this.alertHistoryController, this.alertHistoryNumber, null);
    JSON.parse(JSON.stringify(data));
    return data;
  }

  UpdateAlertIfRead(alertId: number) {
    let data: any = this.HttpPostGeneric(this.alertHistoryController, this.updateAlertyRead, alertId);
    JSON.parse(JSON.stringify(data));
    return data;
  }
  UpdateAllAlertToRead() {
    let data: any = this.HttpPostGeneric(this.alertHistoryController, this.updateAllHistoryIsReadForUser, null);
    JSON.parse(JSON.stringify(data));
    return data;
  }


}
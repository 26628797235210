export const environment = {
  production: false,  
  apiEndpoint: 'https://localhost:7231/',
  websiteBaseURL:  "http://localhost:4200/",
  agritelApiEndpoint: "https://localhost:44390/",
  advisorsImagesUrl: 'https://my.captainfarmer.com/files/Consultants/',
  analysisImagesUrl:"https://files.agritel.com/captainfarmer/Consultants/",
  logUserExperience: false,
  showToggleTheme: false,
  forceHttps:  false,
  environment: "dev",

  advisorInformationAgritelFarmerPlus: {
    titleAdvisor: "Contactez un consultant",
    nameAdvisor: "Hotline Agritel Farmer ",
    phoneAdvisor: "+33 1 59 03 42 91",
    emailAdvisor: "contact@farmer.agritel.com"
  },
  advisorInformationAgritelFarmer: {
    titleAdvisor: "Contactez votre conseiller",
    nameAdvisor: "Céline DURAND",
    phoneAdvisor: "+33 1 59 03 56 21",
    emailAdvisor: "sales@farmer.agritel.com"
  }
};


import { Component, Injectable, OnInit } from '@angular/core';
import { HeaderService } from '../services/header/header.component.service';

@Component({
  selector: 'quotesPage',
  templateUrl: './quotes_page.component.html',
  styleUrls: ['./quotes_page.component.scss']
})

@Injectable()
export class QuotesPageComponent implements OnInit {
  classBlurred: string = '';
  planHasExpired: boolean;

  constructor(private headerService: HeaderService) {
  }

  ngOnInit(): void {
    this.setHeaderTitle();
  }

  setHeaderTitle() {
    this.headerService.setTitle("Cotations");
  }
}
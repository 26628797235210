import { Component, Injectable, OnInit } from '@angular/core';
import * as $ from 'jquery/dist/jquery.min.js';

@Component({
  selector: 'quotesComponent',
  templateUrl: './quotes_component.component.html',
  styleUrls: ['./quotes_component.component.css']
})

@Injectable()
export class QuotesComponent   implements OnInit {
  tableTitle: string = '';
  dateLastUpdate: string = '';
  showDropDownNbLines: boolean = false;
  showRefreshButton: boolean = false;
  tableType: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.UpdateUI();
    this.LoadData(false);
  }

  UpdateUI() {
    if (this.showRefreshButton)
      $("#divButtonRefresh_" + this.tableType).show();
    else
      $("#divButtonRefresh_" + this.tableType).hide();

    if (this.showDropDownNbLines) 
      $("#divDropDownNbLines_" + this.tableType).show();
    else
      $("#divDropDownNbLines_" + this.tableType).hide();
  }

  LoadData(isRefresh: boolean) {
    this.SetTitle();
  }

  SetTitle() {
  }
}

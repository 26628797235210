<div class="divEuronextQuotes">
  <div id="loaderEuronextQuotes" class="loader hidden">
    <div class="loader-wheel"></div>
    <div class="loader-text"></div>
  </div>
  <div class="row header-quotes">
    <div id="divTitleFutureEuronext" class="">
      <div class="divTableTitleEuronext">
        <b>{{tableTitle}}</b>
      </div>
      <div class="divTableIconEuronext">
        <mat-icon class="matIconInfoEuronext" aria-hidden="false" aria-label="info" fontIcon="info" (mouseover)="onImgMouseover($event)" (mouseout)="onImgMouseout($event)"></mat-icon>
      </div>
      <div id="divTooltipFuturesEuronext">
        <div id="divTooltipPriceFuturesEuronext" class="pointedFuturesEuronext">
          <label id="lblTooltipPriceFuturesEuronext"><b>Euronext futures</b> - 15 minutes différé<br /><b>Horaires d'ouverture :</b> 10:45-18:30 CET</label>
        </div>
      </div>
    </div>
    <div id="divDateRefresh_Euronext">
      {{dateLastUpdate}}
      <div id="divButtonRefresh_Euronext" class="spin1" title="Rafraîchir" (click)="RefreshFuturesEuronext()">
        <img id="btRefreshFuturesEuronext" class="imgRefreshQuotes" src="../../assets/images/refresh.png" />
        <label id="lbRefreshFuturesEuronext">Actualiser</label>
      </div>
    </div>
    <div id="divDropDownNbLines_Euronext" class="hidden">
      <label>Lignes</label>
      <select id="dropDownNbLines_Euronext" (change)="onChangeNbLines()" class="select-euronext">
        <option>5</option>
        <option>10</option>
        <option>Max</option>
      </select>
    </div>
  </div>
  <ng-container #productTablesEuronext_Placeholder></ng-container>
</div>

<div id="footer" class="footer">
  <div class="footer-container">
    <div>
      <img src="../../../../assets/images/logo/agritel-farmer/agritelFullLogoWithTextGrey.png" alt="logo agritel farmer"
        width="200">
    </div>
    <div>
    </div>
    <div>
      <ul>
        <legal_mentions></legal_mentions>
      </ul>
    </div>
  </div>
</div>
<div class="divCMEQuotes">
  <div id="loaderCMEQuotes" class="loader hidden">
    <div class="loader-wheel"></div>
    <div class="loader-text"></div>
  </div>
  <div class="row">
    <div id="divTitleFutureCME">
      <div class="divTableTitleCME">
        <b>{{tableTitle}}</b>
      </div>
      <div class="divTableIconCME">
        <mat-icon class="matIconInfoCME" aria-hidden="false" aria-label="info" fontIcon="info" (mouseover)="onImgMouseover($event)" (mouseout)="onImgMouseout($event)"></mat-icon>        
      </div>          
      <div id="divTooltipFuturesCME">
        <div id="divTooltipPriceFuturesCME" class="pointedFuturesCME">
          <label id="lblTooltipPriceFuturesCME"><b>CME futures</b> - 15 minutes différé<br /><b>Horaires d'ouverture de la séance principale :</b> 15:30-20:15 CET</label>
        </div>
      </div>
    </div>
    <div id="divDateRefresh_CME">
      {{dateLastUpdate}}
      <div id="divButtonRefresh_CME" class="spin1 col-sm-3" title="Rafraîchir" (click)="RefreshFuturesCME()">
        <img id="btRefreshFuturesCME" class="imgRefreshQuotes" src="../../assets/images/refresh.png" />
        <label id="lbRefreshFuturesCME">Actualiser</label>
      </div>
    </div>
    <div id="divDropDownNbLines_CME" class="hidden">
      <label>Lignes</label>
      <select id="dropDownNbLines_CME" (change)="onChangeNbLines()" class="select-cme">
        <option>5</option>
        <option>10</option>
        <option>Max</option>
      </select>
    </div>
  </div>
  <ng-container #productTablesCME_Placeholder></ng-container>
</div>

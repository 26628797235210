import { Component } from '@angular/core';

@Component({
  selector: 'app-company-farmers',
  templateUrl: './company-farmers.component.html',
  styleUrls: ['./company-farmers.component.scss']
})
export class CompanyFarmersComponent {

}

import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LoginInformations } from '../../../model/LoginInformations';
import { User } from '../../../tools/User';
import { ConnexionService } from '../../services/connection/connexion.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

@Injectable()
export class LoginComponent implements OnInit {
  isLocal: boolean = false;
  isLoginServiceError = false;
  isSuccessful = true;
  isSignUpFailed = false;
  errorMessage = '';
  token = '';
  hide: boolean = true;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })
  constructor(private connexionService: ConnexionService, private cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.token = this.cookieService.get("token").trim();
    if (this.token != "") {
      User.GlobalFunctionsUser.SetIsConnected(true);
    }
  }

  login() {
    let request: LoginInformations = new LoginInformations();
    request.Email = this.loginForm.value.email;
    request.Password = this.loginForm.value.password;

    this.connexionService.Login(request);
  };

}

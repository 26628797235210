<div class="contact-advisor-container">
  <div class="contact-advisor-title-header">
    <h5 class="contact-advisor-title">{{advisorInformation.titleAdvisor}}</h5>
  </div>
  <div class="contact-advisor-content">
    <div class="container-items">
      <mat-icon aria-hidden="false" aria-p="person icon" fontIcon="person_outline"></mat-icon>
      <p class="contact-advisor-info">{{ advisorInformation.nameAdvisor }}</p>
    </div>
    <div class="separator"></div>
    <div class="container-items">
      <mat-icon aria-hidden="false" aria-p="phone icon" fontIcon="phone"></mat-icon>
      <p class="contact-advisor-info">{{ advisorInformation.phoneAdvisor }}</p>
    </div>
    <div class="separator"></div>
    <div class="container-items">
      <mat-icon aria-hidden="false" aria-p="mail icon" fontIcon="mail_outline"></mat-icon>
      <p class="contact-advisor-info">{{ advisorInformation.emailAdvisor }}</p>
    </div>
  </div>
</div>
import { Component, ComponentFactory, ComponentFactoryResolver, Injectable, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { GlobalFunctions } from '../../../../../global';
import { Dates } from '../../../../../tools/Date';
import { ProductPriceLineComponent } from '../../product_price_line/product_price_line.component';

@Component({
  selector: 'product_table_CME',
  templateUrl: './product_table_CME.component.html',
  styleUrls: ['./product_table_CME.component.scss']
})

@Injectable()
export class ProductTableCMEComponent  implements OnInit {
  @Input() productName: string = '';
  @Input() productCode: string = '';  
  @Input() productType: string;
  @Input() unit: string;
  @Input() multiplier: number;
  @Input() tbodyId: string = '';
  @Input() index: number;  
  @Input() divProductPricesCMEPaddingRight: string = '';
  @Input() productTooltip: string = '';
  data: Array<any> =[];
  
  constructor(private resolver: ComponentFactoryResolver) {
  }

  @ViewChild('productPricesLinesCME_Placeholder', { read: ViewContainerRef })
  public productPricesLinesCME_Placeholder: ViewContainerRef;

   ngOnInit(): void {
  }

   ngAfterViewInit() {
  }

  ImgMouseover() {
    this.ShowProductTooltips_CME();
  }

  DivTooltipFuturesCME_MouseOver() {
    this.ShowProductTooltips_CME();
  }

  DivTooltipFuturesCME_MouseLeave() {
    this.HideProductTooltips_CME();
  }

  DivTooltipFuturesCME_Click() {
    this.HideAllProductTooltips_CME();
  }

  ShowProductTooltips_CME() {
    document.getElementById('divTooltipFuturesCME_' + this.productCode).style['display'] = 'block';
  }

  HideProductTooltips_CME() {
    document.getElementById('divTooltipFuturesCME_' + this.productCode).style['display'] = 'none';
  }

  HideAllProductTooltips_CME() {
    Array.from(document.querySelectorAll('.divTooltipFuturesCME')).forEach(function (val: HTMLElement) {
      val.style.display = 'none';
    });  
  }

  ClearData() {
    if (this.productPricesLinesCME_Placeholder)
      this.productPricesLinesCME_Placeholder.clear();
  }

  LoadData(isRefresh: boolean) {
    var oddBGColor = 'FFFFFF';
    var evenBGColor = 'F3F5F6';    
    var index: number = 0;

    this.data.forEach(line => {
      if (!isRefresh) {      
        let factory: ComponentFactory<ProductPriceLineComponent> = this.resolver.resolveComponentFactory(ProductPriceLineComponent);
        let product_price_line = this.productPricesLinesCME_Placeholder.createComponent(factory);
      
        var bgColorRow: string = (index % 2 == 0) ? oddBGColor : evenBGColor;
        
        var dateExpiry: Date = new Date(line.realDateExpiry);
        var monthNumberExpiry: number = dateExpiry.getMonth() + 1;
        var yearExpiry: string = dateExpiry.getFullYear().toString().substring(2);
        var cellCssClass: string = (index < 5) ? "tdCellProductPriceVisible" : "tdCellProductPriceHidden";
        // var bgColorVariation: string = (line.variation < 0 ? "E00000" : (line.variation > 0 ? "00840D" : "0094FF"));
        var bgColorVariation: string = (line.variation < 0 ? "E00000" : (line.variation > 0 ? "00840D" : "00840D"));


        product_price_line.instance.codeExpiry = line.codeExpriy;
        product_price_line.instance.textExpiry = GlobalFunctions.CapitalizeFirstLetter(Dates.GlobalFunctionsDates.GetMonthShortName(monthNumberExpiry)) + " " + yearExpiry;
        product_price_line.instance.variation = Math.abs(line.variation).toFixed(2);
        product_price_line.instance.variationSign = (line.variation > 0) ? "+" : (line.variation < 0)?"-":"";
        product_price_line.instance.price = line.close.toFixed(2);
        product_price_line.instance.bgColorRow = bgColorRow;
        product_price_line.instance.index = index;
        product_price_line.instance.rowIndex = index+1;
        product_price_line.instance.cellCssClass = cellCssClass;
        product_price_line.instance.bgColorVariation = bgColorVariation;
        product_price_line.instance.productCode = this.productCode;
        product_price_line.instance.productName = this.productName.trim();
        product_price_line.instance.productType = "CME";
        product_price_line.instance.multiplier = this.multiplier;
        product_price_line.instance.unit = this.unit;
      }

      index++;
    });
  }
}

import { Component, Injectable, OnInit } from '@angular/core';
import { GlobalEnums, GlobalFunctions } from '../../../../global';
import { User } from '../../../../tools/User';
import { HeaderService } from '../../../services/header/header.component.service';
import { CompanyService } from '../../../services/http/company.service';
import { UserService } from 'src/app/services/http/user.service';
import { NotificationService } from '../notifications/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

@Injectable()
export class HeaderComponent implements OnInit {
  title = '';
  classBlurred: string = '';
  isAgritelPlus: boolean = false; 
  logoUrl: string = "../../../assets/images/logo/agritel-farmer/agritelLogoWhite.png";

  constructor(private companyService: CompanyService, private headerService : HeaderService, private userService : UserService, private notificationService : NotificationService) { }

  ngOnInit(): void {

    if (User.GlobalFunctionsUser.GetIsConnected()) {
      this.companyService.PlanHasExpired().subscribe(() => {
        this.classBlurred = (this.companyService.PlanHasExpired()) ? "blurred" : "";
      },
        err => {
          if (err.status !== 401) {
          }
        });
    }
    this.headerService.title.subscribe(updatedTitle =>{
      this.title = updatedTitle
    })
    this.getUserIdProfil(); 
  } 

  GetStrategyDateFormatted(): string {
    return GlobalFunctions.GetStrategyDateFormatted();
  }

  GetIsConnected(): boolean {
    return User.GlobalFunctionsUser.GetIsConnected();
  }


  GetName(): string {
    return User.GlobalFunctionsUser.GetFirstName();
  }

  getUserIdProfil() {
    this.userService.GetIdProfil().subscribe({
      next: (value) => {
        switch (value.idProfil) {
          case 1:
            this.isAgritelPlus = false;
            this.logoUrl = "../../../assets/images/logo/agritel-farmer/agritelLogoWhite.png";

            break;
          case 2:
          case 3:
          case 4:
            this.isAgritelPlus = true;
            this.logoUrl = "../../../assets/images/logo/agritel-plus/agritelPlusLogoWhite.png";

            break;
        }
      },
      error: (err) => {
        if (err.status !== 401) {
          this.notificationService.ShowError("Erreur lors du chargement des informations.", "");
        }
      },
      complete: () => {},
    })
  }
}

import { Injectable } from '@angular/core';
import { ReportNotificationType } from '../../../model/ReportTypeNotification';

import { Service } from './service';

@Injectable({
  providedIn: 'root'
})

export class ReportService extends Service {
  override controller = 'Report';
  getHistoryReportsNumber: string = 'GetHistoryReportsNumber';
  saveSentReportUser: string = 'SaveSentReportUser';
  getSentReportUser: string = 'GetSentReportUser';  

  GetHistoryReportsNumber() {
    let data: any = this.HttpPostGeneric(this.controller, this.getHistoryReportsNumber, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  SaveSentReportUser(reportsTypes: Array<ReportNotificationType>): any {
    let data: any = this.HttpPostGeneric(this.controller, this.saveSentReportUser, reportsTypes);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetSentReportUser(): any {
    let data: any = this.HttpPostGeneric(this.controller, this.getSentReportUser, null);

    JSON.parse(JSON.stringify(data))

    return data;
  }
}




<tr id="{{rowIndex}}"  style="{{'background-color: #' + bgColorRow}}">
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="{{'text-align:center;' + lastRowBorderLeft}}">
    <label class="label-product-price-line" style="color: #000000;">{{textExpiry}}</label>
  </td>
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="text-align:center;">
    <label class="label-product-price-line" id="lblPriceFuture{{productType}}_{{productCode + '_' + codeExpiry}}" style="color:#000000;font-variant-numeric:tabular-nums;">{{price}}</label>
  </td>
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="text-align:center;padding-left:0;padding-right:0;">
    <div>
      <label class="lblVariationSignFuture" id="{{'lblVariationSignFuture' + productType + '_' + productCode + '_' + codeExpiry}}" style="{{'background-color:#' + bgColorVariation + ';'}}">{{variationSign}}</label>
      <label class="lblVariationValueFuture" id="{{'lblVariationValueFuture' + productType + '_' + productCode + '_' + codeExpiry}}" style="{{'background-color:#' + bgColorVariation}}">{{variation}}</label>
    </div>
  </td>
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="{{'text-align:center;' + lastRowBorderRight}}">    
    <mat-icon class="imgProductPriceFuture{{productType}} material-icons-chart" attr.codeExpiry="{{codeExpiry}}" attr.textExpiry="{{textExpiry}}" attr.productName="{{productName}}" attr.productCode="{{productCode}}" (click)="ImgGraphClick()" aria-hidden='false' aria-label='insert_chart' title='Graphique' fontIcon='insert_chart'></mat-icon>
  </td>
</tr>

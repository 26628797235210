import { Component, Injectable, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';

@Component({
  selector: 'app-expired-modal',
  templateUrl: './expired_plan_popin.component.html',
  styleUrls: ['./expired_plan_popin.component.scss']
})

@Injectable()
export class ExpiredPlanPopinComponent implements OnInit {
  rightEnd: string = '';
  planHasExpired: boolean = false;
  textRenewPlan: string = '';
  planId: number;


  constructor(private notifyService: NotificationService, private companyService: CompanyService) {}

  ngOnInit(): void {
    this.PlanHasExpired();
    this.GetRightEnd();
    this.getPlanId(); 
  }

  PlanHasExpired() {
    this.companyService.PlanHasExpired().subscribe((data: boolean) => {
      this.planHasExpired = data;
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }

  GetRightEnd() {
    this.companyService.GetRightEnd().subscribe((data: { rightEnd: string; }) => {
      this.rightEnd = data.rightEnd;
    },
      (err: { status: number; }) => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }


  getPlanId(){
    this.companyService.GetPlan().subscribe({
      next: (value: { idProfil: any; }) => {
        this.planId = value.idProfil;
        switch (value.idProfil) {
          case 1:
            this.textRenewPlan = "S'abonner";
            break;
          case 2:
          case 3:
          case 4:
            this.textRenewPlan = "Renouveller mon offre";
            break;

        }
      },
      error: (err: { status: number; }) => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur lors du chargement des informations.", "");
        }
      },
      complete: () => {},
    })
  }
}

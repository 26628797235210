<div [style.display]="banner.isEnabled ? 'block':'none'" class="banner-container">
  <div class="container-content">
    <div>
      <svg width="34px" height="34px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
          stroke-width="0.528"></g>
        <g id="SVGRepo_iconCarrier">
          <circle cx="12" cy="12" r="10" stroke="#00A381" stroke-width="1.5"></circle>
          <path d="M12 17V11" stroke="#00A381" stroke-width="1.5" stroke-linecap="round"></path>
          <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#00A381"></circle>
        </g>
      </svg>
    </div>
    <div [innerHTML]="banner.text | safeHtml" class="banner-text"></div>
  </div>

</div>
<div id="divProductPriceCME_{{index}}" class="col-sm-4 mainDivProductPriceCME">
  <div class="divProductPricesCME" style="{{divProductPricesCMEPaddingRight}}">
    <div class="row rowProductPriceCME">
      <div id="divTitle" class="col-sm-12 divTitleProductPriceCME">
        <div class="divTitleProductPriceCME2">
          <div class="divTitleProductPriceCME3">
            <b>{{productName}}</b>
          </div>
          <div class="divIconChart" onmouseleave="Array.from(document.querySelectorAll('.divTooltipFuturesCME')).forEach(function (val) {val.style.display='none' ;});">
            <mat-icon id="imgInformationCME_{{productCode}}" class="imgInformationProductPrice" aria-hidden="false" aria-label="info" fontIcon="info" (mouseover)="ImgMouseover()"></mat-icon>
          </div>
          <div id="divTooltipFuturesCME_{{productCode}}" class="divTooltipFuturesCME" (mouseover)="DivTooltipFuturesCME_MouseOver()" (mouseleave)="DivTooltipFuturesCME_MouseLeave()" (click)="DivTooltipFuturesCME_Click()">
            <div id="divTooltipPriceFuturesCME_{{productCode}}" class="pointedProductFutureCME">
              <div id="lblTooltipPriceFuturesCME_{{productCode}}" [innerHTML]="productTooltip | safeHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped tblProductPrices" id="table_product_price_{{productCode}}">
      <thead>
        <tr class="trProductPricesHeader">
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Date</label>
          </th>
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Prix ({{unit}})</label>
          </th>
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Variation</label>
          </th>
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Graphique</label>
          </th>
        </tr>
      </thead>
      <tbody class="table_product_price_CME_summary_body" id="table_product_price_CME_summary_body{{productCode}}">
        <ng-container #productPricesLinesCME_Placeholder></ng-container>
      </tbody>
    </table>
  </div>
</div>

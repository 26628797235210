<div class="crop-container">
  <div class="crop-title">
    <p>{{title}}</p>
  </div>
  <div class="form-crop-container">

    <form action="" [formGroup]="cropForm" (ngSubmit)="saveForm()">
      <div class="crop-item-container">
        <div class="crop-item">
          <div>
            <img src="../../../assets/images/icon-analysis/ble_tendre.png" alt="" width="35">
            <input type="checkbox" name="bleTendre" [checked]="allCrop.bleTendre" formControlName="bleTendre" id="">
          </div>
          <p class="crop-name">Blé tendre</p>

        </div>
        <div class="crop-item">
          <div class="img-and-input">
            <img src="../../../assets/images/icon-analysis/mais.png" alt="" width="35">
            <input type="checkbox" [checked]="allCrop.mais" name="mais" formControlName="mais" id="">
          </div>
          <p class="crop-name">Mais</p>

        </div>
        <div class="crop-item">
          <div class="img-and-input">
            <img src="../../../assets/images/icon-analysis/colza.png" alt="" width="35">
            <input type="checkbox" [checked]="allCrop.colza" formControlName="colza" name="colza" id="">
          </div>
          <p class="crop-name">Colza</p>

        </div>
        <div class="crop-item">
          <div class="img-and-input">
            <img src="../../../assets/images/icon-analysis/orge_fourragere.png" alt="" width="35">
            <input type="checkbox" [checked]="allCrop.ourgeFourragere" formControlName="ourgeFourragere"
              name="ourgeFourragere" id="">
          </div>
          <p class="crop-name">Orge fourragère</p>

        </div>
        <div class="crop-item">
          <div class="img-and-input">
            <img src="../../../assets/images/icon-analysis/orge_brassicole.png" alt="" width="35">
            <input type="checkbox" [checked]="allCrop.orgeBrassPrin" formControlName="orgeBrassPrin"
              name="orgeBrassPrin" id="">

          </div>
          <p class="crop-name">Orge brassicole de printemps</p>

        </div>
        <div class="crop-item" >
          <div class="img-and-input">
            <img src="../../../assets/images/icon-analysis/tournesol.png" alt="" width="35">
            <input type="checkbox" name="tournesol" id="" formControlName="tournesol" [checked]="allCrop.tournesol">
          </div>
          <p class="crop-name">Tournesol</p>

        </div>
        <div class="crop-item">
          <div class="img-and-input">
            <img src="../../../assets/images/icon-analysis/ble_dur.png" alt="" width="35">
            <input type="checkbox" [checked]="allCrop.bleDur" formControlName="bleDur" name="bleDur" id="">
          </div>
          <p class="crop-name">Blé dur</p>
        </div>
        <div class="crop-item">
          <div class="img-and-input">
            <img src="../../../assets/images/icon-analysis/engrais.png" alt="" width="35">
            <input type="checkbox" [checked]="allCrop.engrais" formControlName="engrais" name="engrais" id="">
          </div>
          <p class="crop-name">Engrais</p>

        </div>

      </div>
      <br>
      <button type="submit" class=" buttonFullTextBold buttonNormalcase width_50">Sauvegarder</button>
    </form>
  </div>
</div>
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-teaser',
  templateUrl: './login_teaser.component.html',
  styleUrls: ['./login_teaser.component.scss']
})

export class LoginTeaserComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
